.cart__items__products__extras
    display: flex
    flex-direction: row
    flex-grow: 1
    position relative
    margin: 8px 0px
        
    > div:first-child
        width: 48px
        height: 48px
        background-color: var(--background-extra-light)
        border: 1px solid var(--border-color)
        border-radius: 6px
        display: flex
        align-items: center
        justify-content: center

        img, svg
            max-width: 100%
            max-height: 100%
    
    > div:nth-child(2)
        width: 100%
        padding-left: 8px
        padding-right: 32px

        span 
            text-align: left
            font-size: 0.71rem

.cart__items__pricing__table
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr
    grid-auto-flow: row
    width: 100%
    
    > div
        display: flex
        flex-flow: column
        flex: 1
        span
            padding: 4px 8px
            white-space: nowrap

        span:last-child
            background: var(--background-extra-medium)
        
            > div 
                text-align: left
                font-size: 0.71rem

.cart__items__remove__btn__container
    position: absolute
    top: 0
    right: 0
    
    .cart__items__remove__btn__container__button
    .cart__items__remove__btn__container__button:hover
    .cart__items__remove__btn__container__button:focus
      width: 16px
      height: 16px
      padding: 0
      background: none

      img
        width: 16px

.rightalign-value
    text-align: right !important