.button
  position: relative
  word-break: initial
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  vertical-align: middle
  height: 40px
  padding: 8px 20px
  border-width: 0
  border-radius: var(--border-radius)
  font-family: var(--typography-body)
  font-size: 1rem
  cursor: pointer
  outline: none
  color: var(--button-text-default)
  background-color: var(--button-default)
  transition: var(--transition-slow)

  &.inverted
    background: var(--button-text-default)
    color: var(--button-default)
    border-width: var(--border-width)
    border-color: var(--button-default)
    border-style: solid

  &.loading
    .spinner
      width: 40px
      height: 40px
      position: absolute
      top: calc(50% - 20px)
      left: calc(50% - 20px)

      div
        background: #fff

  // Since this type of button is used in several places with several sizes,
  // we choose to allow them to occupy all the vertical space made available by
  // its parent and control the size of the buttons there, instead of giving a
  // fixed size to the buttons.
  &.big-square
    min-height: 100%
    width: 100%

    img
      display: block
      width: auto
      height: 66.66%
      margin: auto

  &.small
    height: 24px
    min-width: 24px
    padding: 0px 8px
    border-radius: var(--border-radius-small)

  &.large
    width: 100%
    min-width: 180px
    height: 56px

    .spinner
      top: calc(50% - 15px)
      left: calc(50% - 15px)
      width: 30px
      height: 30px

  &:disabled
    background: var(--button-default)
    opacity: .5
    cursor: default

  &:not(:disabled):hover,
  &:not(:disabled):focus,
  &.selected
    text-decoration: none
    color: var(--button-text-highlight)
    background-color: var(--button-highlight)
    border-color: var(--button-highlight)
    transform: var(--transition-slow)
    cursor: pointer

  &.red
    background-color: var(--redalert-state)
    color: var(--color-redalert)

    &:hover
      color: var(--color-txt-light)
      background: var(--redalert-state-hover)

  &.light
    color: var(--color-extra-dark)
    background-color: rgba(255,255,255, 0.5)

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &.selected
      color: var(--color-extra-dark)
      background-color: rgb(255,255,255, 0.5)
  
  &.onlyIcon,
  &.onlyIcon:hover,
  &.onlyIcon:focus
    width: 16px
    height: 16px
    margin: 0
    padding: 0
    background: none
    border-radius: 0
    overflow: hidden

    img, svg
      width: 16px
      height: 16px

  &__half-transparent
    background-color: transparent

    &:before
      position: absolute
      content: ""
      width: 50%
      height: 50%
      background-color: var(--button-default)
      border-radius: var(--border-radius)
      z-index: -1

  &.transparent
    color: var(--color-txt-light)
    background-color: transparent

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &.selected
      color: var(--color-txt-light)
      background-color: transparent
      cursor: pointer

  &.border-only, &.border-only.button:hover, &.border-only.button:focus
    background: transparent
    border: 2px solid
    color: #000
