.manualItemForm

  .modal
    min-height: 32vh

    .modal-content
      min-height: 32vh

    .manual-item-form
      width: 100%
      display: flex
      justify-content: space-between
      padding: 8px 8px 8px 0
      div:first-child
        flex-basis: 50%
      > div
        margin-right: 24px

        input
          width: 100%
      .input__wrapper
        flex-basis: 25%