*
  box-sizing: border-box

html
  font-family: var(--typography-body)
  font-size: var(--typography-size)
  font-weight: var(--typography-normal)
  line-height: var(--typography-line-height)
  color: var(--color-txt-dark)

body
  margin: 0
  padding: 0

h1, h1.heading, h2, h2.heading, h3, h4
  font-family: var(--typography-title)
  font-weight: var(--typography-bold)

#app
  display: flex
  flex-direction: column
  width: 100%
  overflow: auto
  min-height: calc(100vh - var(--layout-footer-height))

  &.grey-bg
    background-color: var(--online-grey)

  .img-pixel
    display: none

  .tabbedContent
    .tabbedContent--tab
      flex-grow: 1

  main
    > div[tabindex='-1'],
    #content
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      position: relative
      width: 100%
      max-width: var(--layout-max-width)
      margin: 0 auto
      padding: 24px 24px 80px
      outline: none

      > #upgrade-flow,
      > #standard-flow
        display: flex
        width: 100%
        padding: 0

  .multistep
    margin-bottom: 24px

  .footer
    position: fixed
    z-index: 500
    left: 0
    bottom: 0

  h2.heading
    margin-bottom: 16px

  .button.admin-main-action
    float: right
    margin-bottom: 1em

#admin
  width: 100%

  .pagination
    margin-bottom: 0

  .footer
    position: fixed
    bottom: 0

.img-pixel
  display: none

h1, h1.heading
  font-size: 1.938rem
  margin: 0 0 24px
  padding: 0

  &.admin-page-title
    width: 100%
    margin-bottom: 24px

h2, h2.heading
  font-size: 1.250rem
  margin: 0
  padding: 0

h3
  margin: 0
  padding: 0
  font-size: 1rem

  &.admin-page-subtitle
    width: 100%
    margin-top: 24px
    margin-bottom: 24px

ul
  margin: 0
  padding: 0

form
  & > div
    position: relative

  .input__floating-label
    z-index: 1
    color: var(--color-txt-light)
    font-size: 1rem
    font-weight: normal
    position: absolute
    pointer-events: none
    left: 8px
    top: 12px
    transition: all 0.2s ease

    display: block

    &.active
      top: -10px
      transform: scale(.75)
      left: -4px
      display: block

  .input-container
    padding-bottom: 24px

  p
    margin: 8px 0 0 8px
    padding: 0 0 0
    font-size: 0.85rem
    text-align: left

  p.error
    color: var(--redalert-state)

input:not([type=checkbox]):not([type=radio])
  -webkit-appearance: none

::-webkit-input-placeholder
  color: var(--color-txt-light)

::-moz-placeholder
  color: var(--color-txt-light)

:-ms-input-placeholder
  color: var(--color-txt-light)

:-moz-placeholder
  color: var(--color-txt-light)

input:-webkit-autofill
  -webkit-box-shadow: inset 0 0 0px 9999px white

input,
.input,
select,
.select
  position: relative
  height: 40px
  padding: 0 8px
  font-family: var(--typography-body)
  font-size: 1rem
  color: var(--color-txt-dark)
  border-radius: 0
  border: 0
  border-bottom: 2px solid var(--border-color)
  background-color: var(--input-background)
  transition: var(--transition-slow)

  &:focus
    border: 0
    border-bottom: 2px solid var(--border-color-active)
    outline: none

.input-container
  position: relative

  input, button
    width: 100%

    &.form-error
      border-color: var(--redalert-state)

    &.form-ok
      border-color: var(--state-greenalert)

  .password-mask
    position: absolute
    top: 3px
    right: 2px
    background: var(--input-background)
    width: 30px
    height: 30px
    margin: 0
    transition: var(--transition-slow)
    cursor: pointer

  p.error
    padding: 5px 8px 0
    margin: 0
    color: var(--redalert-state)

.line-item.filled
  font-weight: inherit

.badges
  & .badge
    padding: 4px 8px
    color: var( --color-txt-dark)
    font-size: 1rem
    border: 0
    &:first-child
    &:last-child
      border-radius: 4px

.details 
  align-items: flex-start

  img
    width: 64px
    height: auto
    margin: 8px

h2 .badges > .sale-page__badge
  font-weight: normal