.cart-summary
  position: relative
  top: 0
  text-decoration: none
  font-size: 1rem
  color: var(--color-txt-dark)
  height: 2rem
  padding-left: 1rem
  text-align: right

  img
    display: inline-block
    position: relative
    top: -3px
    vertical-align: middle
    width: 40px
    height: 100%
    margin-right: .45rem