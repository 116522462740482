.swap-legal-text__result
  font-size: 16px
  p:first-child
    margin-top: 0
  p:last-child
    margin-bottom: 0

  .swap-legal-text__description
    margin-bottom: 8px

  .swap-legal-text__description,
  .swap-legal-text__device-value
    width: fit-content
    border-radius: var(--online-border-radius)
    font-family: var(--typography-title)

  .swap-legal-text__description
    background: var(--online-approved)
    margin: var(--button-spacing) 0

  .swap-legal-text__disclaimer--header
    p:first-child
      font-family: var(--typography-title)
      margin-top: 0