.recycleSearch
  width: 100%
  margin-bottom: 110px

  .heading
    margin-bottom: 24px


  .tabbedContent--content
    padding: 16px

  form
    width: 33.33%

  .recycleSearch__tooltip
    display: flex
    cursor: pointer
    margin-top: 16px
    line-height: 16px
    font-size: .95rem
    color: var(--button-default)
    text-decoration: underline
    align-items: center

    .icon__info-icon
      width: 22px
      height: 22px
      margin-right: 8px
      border-radius: 50%
      fill: var(--info-icon)
      border: 2px solid var(--info-icon)

    &:not(:disabled):hover
    &:not(:disabled):focus
      text-decoration: none

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)
  .recycleSearch
    form
      width: 50%

      > div
        width: 50%

      button
        min-width: 50%

@media all and (max-width: 768px)
  .recycleSearch
    form
      width: 75%
      > div
        width: 50%
      button
        min-width: 30%