.financing__status__heading
    display: flex
    align-items: center
    width: 100%
    padding: 8px 0px
    >h2
        flex : 1
    span 
        align-self: center
        padding-right: 16px

        &.Connected
            color: var(--greenalert-state)
        &.Disconnected
            color: var(--redalert-state)

.states__list
    border-collapse:collapse;
    margin-top: 8px
    thead
        tr
            background: var(--background-extra-medium)
            th
                text-align: left
                padding 0px 8px
    tr
        line-height: 40px
        td 
            padding 0px 8px
            &:first-child
                text-align: center
            &:nth-child(2)
                font-family: var(--typography-title)
            
        img
            position relative
            top 8px
            width: 24px
            height: 24px