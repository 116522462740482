.multisearch-heading
  width: 100%
  margin-bottom: 16px
.tradecart-counter
  margin-bottom: 16px
  flex: auto
  text-align: right
.tradecart-disowned-warning
  margin-bottom: 16px
.tradecart-info-values
  background-color: var(--online-grey)
.tradecart-wrapper
  display: flex
  width: 100%  
  .tradecart-details
    justify-content: start
    
    &.non-working
      border: 2px solid #d90000

    .tradecart-index
      line-height: 40px
    
    .tradecart-device
      flex-direction: column
      width: 100% 
      display: flex

      &-summary
        line-height: 40px
        display: flex
        width: 100%
        margin-bottom: 24px

      .device-details
        width: 100%
        .device-properties
          line-height: 2
        .device-name, .device-property
          font-weight: bold
        .device-name
          display: flex
          svg
            margin-top: 6px

      .device-price
        display: flex
        text-align: right
        flex-direction: column
        &-promotion
          line-height: 2
          background-color: #E8E8E8
          padding: 0 10px
          border-radius: 4px

      .device-image
        padding: 0 12px
        height: 33px

      .deviceReferenceForm 
        width: 30%

        .deviceReferenceForm__input
          margin-top: 0

      .nwz-warning
        color: #D90000
        margin-left: 48px
        font-weight: bold
    .promotions
      width: 50%
  
  .tradecart-remove
    fill: #D90000
    width: 40px
    height: 40px
    margin-top: 16px
    cursor: pointer
    svg
      width: 100%
      height: 100%
