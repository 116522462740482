.timeline
  display: flex
  flex-direction: row
  flex-wrap: wrap
  width: 100%
  padding: 50px 0
  border-top: 1px solid var(--border-color)
  border-bottom: 1px solid var(--border-color)

  &__label
    font-weight: var(--typography-bold)
    margin: 38px 10px

    span
      display: block
      margin-top: 2px

    &--align-right
      text-align: right

  &__bar
    position: relative
    flex-grow: 2

    &-today
      position: absolute
      width: fit-content
      top: -30px
      transform: translateX(-50%)
      padding: 4px
      border: 1px solid var(--border-color)
      border-radius: 2px
      background: #efefef

      &::after
        position: absolute
        top: 25px
        left: 50%
        content: ''
        border-left: 1px dashed var(--border-color-extra)
        height: 88px

  &__caption
    position: relative
    flex-basis: 100%
    margin: 50px 0 0 110px
    padding-left: 20px
    font-size: 1em

    &::before
      position: absolute
      content: ''
      top: -1px
      left: 0
      width: 16px
      height: 16px
      background: #FFB3BF

.period
  position: absolute

  &__date
    text-align: center
    height: 38px
    margin: 0
    transform: translateX(50%)
    white-space: nowrap
    background: url('assets/images/arrow-down.svg') no-repeat 50% 75%

  &__bar
    width: 100%
    height: 46px
    border-left: 1px dashed var(--border-color-extra)
    border-right: 1px dashed var(--border-color-extra)
    padding: 0px 3px

    &--green
      background: linear-gradient(to bottom, #93BF18, #93BF18 70%, white 70%)
      background-clip: content-box

    &--orange
      background: linear-gradient(to bottom, #F2A50C, #F2A50C 70%, white 70%)
      background-clip: content-box

    &--red
      background: linear-gradient(to bottom, #D92B47, #D92B47 70%, white 70%)
      background-clip: content-box

    &--pink
      background: linear-gradient(to bottom, #FFB3BF, #FFB3BF 70%, white 70%)
      background-clip: content-box

    &--borderless
      border: 0px

    &--offset
      margin-top: 38px

  &__title
    font-weight: var(--typography-bold)
    text-align: center
    margin: 0

  &__subtitle
    text-align: center
    margin-top: 0
    font-size: 0.9em
