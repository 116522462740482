.pagination
  display: flex
  justify-content: center
  width: 100%
  padding: 24px 0 24px

  .button
    width: 40px
    padding: 0 0
    text-align: center
    font-size: 1.4rem
    color: var(--color-txt-dark)
    font-weight: var(--typography-bold)
    background-color: var(--background-extra-medium)

    span
      position: relative
      top: -1px

    &:hover,
    &:focus
      background-color: var(--background-extra-dark)

  .button:disabled
    background-color: var(--background-extra-medium)

  .page-indicator
    display: inline-block
    margin: 0 24px
    line-height: 40px

.pagination-load-more
    button
      margin: 24px auto
      display: flex

      img
        height: 20px
        transform: rotate(-90deg)
        margin-left: 10px