.list-title
  margin-bottom: 0
  text-transform: capitalize
  font-weight: normal

.list
  list-style: none
  display: flex
  flex-wrap: wrap
  margin-bottom: 1rem
  padding: 1rem 0 0
  border-bottom: 1px solid var(--border-color)

  .item
    padding: .5rem
    margin-bottom: 1rem

  &:last-child
    margin: 0 0 16px
    border: 0

&.keyboard+.dropdown
  margin-bottom: 1rem
  padding: 0 0 4rem
  border-bottom: 1px solid var(--border-color)

  .option-container
    width: 33.33%

/* ============================ Media Queries ============================ */

@media all and (max-width: 1366px)
  .list
    .item
      flex-basis: 50%

@media all and (max-width: 1024px)
  .list
    .item
      flex-basis: 100%

  &.keyboard+.dropdown
      width: 100%