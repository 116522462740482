.search-bar-box
  width: 21%
  margin-bottom: 2rem
  margin-right: 2rem
  float: left

  .search-filter-wrapper
    width: 100%
    margin-right: 0

    .search-input
      width: 100%

      input
        width: 100%

    .search-input-icon
      position: absolute
      top: 3px
      right: 8px
      background: var(--input-background)
      width: 24px
      height: 24px
      margin: 0
      transition: var(--transition-slow)

    label
      z-index: 1
      color: var(--color-txt-light)
      font-size: 1rem
      font-weight: normal
      position: absolute
      pointer-events: none
      left: 8px
      top: 12px
      transition: all 0.2s ease
      width: 100%
      display: block

      &.active
        top: -10px
        transform: scale(.75)
        left: -10%
        display: block

  form
    flex: 1
    max-width: 15.15%

@media all and (max-width: 1024px)
  .search-bar-box
    width: 33%