.quote-receipt.print
  display: block
  font-size: 10pt

  .btn-print
    float: right
    height: auto
    margin-bottom: 24px

    img
      height: 32px
      vertical-align: middle
      margin-right: 10px

  .quote-info
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    justify-content: space-between
    width: 100%
    margin-bottom: 24px

  .print-title

    .description-list
      width: auto
      padding-right: 0

      > div
        flex: auto
        padding-right: 0
        border: 0

        &:after
          content: "|"
          margin-left: 8px

        .label
          font-weight: var(--typography-bold)

          &:after
            content: ":"

      > div:last-child
        flex: auto
        padding-right: 0
        border: 0

        &:after
          content: ""
          margin-left: 0

    &-logo
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      align-items: center
      width: 100%
      height: 40px

      svg, img
        display: inline-block
        height: 100%

    .details.inline.dates
      width: 100%

  .cust-retail-data
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: flex-start
    width: 100%
    margin: 24px 0

    ul
      width: 50%
      li
        list-style: none
        padding-bottom: 8px

        h2
          font-size: 1em

        &:empty
          display: none

      &:last-child
        text-align: right

  .product-img
    height: 120px
    width: 10%
    padding: 16px 8px

    img
      height: auto
      display: block
      margin: auto

  .summary-product
    margin-bottom: 16px

    .line-item
      height: 32px

  .item-details
    h2
      border: 0
      font-size: 1em

  .total-print-page
    display: flex
    align-items: center
    justify-content: space-between
    height: 32px
    padding: 0 8px 0px
    margin-top: 2px
    margin-left: 15%
    overflow: hidden
    text-overflow: ellipsis
    background: var(--background-extra-medium)
    font-weight: var(--typography-bold)

  .total-print-page+div
    width: 100%
    margin-top: 24px

    p
      margin: 0 0 8px
      line-height: 1.4em

  .divider
    width: 100%
    margin: 36px 0 24px
    text-align: center
    border-top: 3px dashed var(--border-color)

    h2
      display: inline
      position: relative
      top: -12px
      padding: 24px 16px
      background: var(--background-extra-light)

  .sign-area
      margin-top: 24px
      margin-bottom: 24px
    .sign-space
      display: flex
      font-family: var(--typography-title)

      .sign-line
        width: 20%
        margin-left: 8px
        border-bottom: 1px solid var(--border-color)

  .receipt-text
    &__spain
      ul
        padding-left: 16px
        li
          list-style: initial

    &__portugal
      ul
        padding-left: 16px
        li
          list-style: initial

  .quote-receipt__portugal
    .btn-print
      height: 0
      margin-bottom: 0

    .quote-info
      margin-bottom: 16px

    .receipt-outcomes h3, 
    .receipt-outcomes span
      margin: 8px 0 0
  
    .cust-retail-data
      margin: 8px 0 16px
      
      li
        padding-bottom: 4px

    .receipt-text__portugal
      font-size: 10px
      line-height: 12px

    .sign-area
      margin-top: 8px

/* ============================ Media Queries ============================ */

@media print
  body
    margin: 0
    -webkit-print-color-adjust: exact !important
  
  .notification-wrapper
    display: none
    
  div[role="group"]
    display: block !important

  #app
    display: block
    overflow: visible
    &.grey-bg 
      background-color: transparent
      .section
        border-color: transparent
    main
      display: block
      height: 100%

  header
  .quote-receipt .header
  .footer
    display: none
    page-break-inside: avoid

  .quote-receipt.print
    .print-title
      margin-top: 1em

      .receipt-outcomes
        margin: 0

      .details.inline.dates
        .line-item
          height: 24px
          min-height: 24px

    .trade-summary.product-summary
      .summary-product
        margin-bottom: 8px
        .item-details
          .line-item
            min-height: 30px

    .divider
      display: block
      margin-top: 4em
      page-break-before: always

    .cust-retail-data
      ul
        li
          padding-bottom: 0

    p
      margin-block-start: 0
      margin-block-end: 0

    .receipt-text__spain
      font-size: 11px
      line-height: 14px

  .quote-receipt__portugal.print
    .btn-print
      margin-bottom: 16px

    .quote-info
      margin-bottom: 16px

    .receipt-outcomes h3, 
    .receipt-outcomes span
      margin: 8px 0 0
  
    .cust-retail-data
      margin: 8px 0 16px
      
      li
        padding-bottom: 2px

    .receipt-text__portugal
      font-size: 10px
      line-height: 12px

    .sign-area
      font-size: 11px
      margin-top: 8px

@page
  size: A4
  margin: 0cm
  orphans: 4
