.recycleEvaluation
  width: 100%
  display: flex

  .criteria
    padding: 16px

  .info
    cursor: pointer
    line-height: 16px
    display: inline-flex
    color: var(--greenalert-state)

  p
    margin-bottom: 32px

  .multipleEvalsInfo
    background: #F2F2F2
    padding: 16px

  .promotions
    min-height: 150px
    margin: 16px

  aside
    .trade-summary
      .summary-product
        display: flex
        flex-direction: row

        .product-img
          width: 80px
          height: 80px

          img 
            width: auto
            height: 100%

        .item-details
          flex-grow: 1

          > div > span:first-child
            font-family: var(--typography-title)

          > div:first-child > span:first-child
            font-size: 1.2rem

          button
            display: none