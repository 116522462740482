.pending-actions__container
  position: relative
  width: 100%

  .pending-actions__heading
    color: var(--redalert-state)

  .actions__heading
    color: var(--redalert-state)
  
  .pending-actions__contract
    list-style: none
    width: 50%
    float: left
    margin-bottom: 48px
    
    li
      display: flex
      align-items: center

      p 
        padding-left: 40px;

  ul:nth-child(3)
    border-left: 1px solid var(--border-color)
    padding-left: 16px

    li
      margin: 8px 0


  .pending-actions__icon--error,
  .pending-actions__icon--alert,
  .pending-actions__icon--approved
    width: 22px
    height: 22px
    margin: 8px

  .pending-actions__icon--error
    color: var(--redalert-state)
  
  .pending-actions__icon--alert
    color: var(--yellowalert-state)

  .pending-actions__icon--approved
    color: var(--greenalert-state)

  .pending-actions__buttons
    position: absolute
    bottom: 0
    width: 100%

    button:last-child
      margin-right: 0
    button
      height: 32px
      padding: 8px
      margin-right: 16px
    button.red
      float: right