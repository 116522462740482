.multistep
  display: grid
  position: relative
  width: 100%
  margin: 0 auto
  clear: both

  &__circle
    width: 26px
    height: 26px
    margin-right: 14px
    border-radius: 100%
    font-size: 1rem
    line-height: 25px
    text-align: center
    background: var(--color-extra-dark)
    color: var(--color-extra-light)
    float: left

  &__currentStep
    svg
      height: 26px

  h1
    margin: 0
    margin-top: -3px
    padding: 0 0
    float: left

  &__missingStep
    background: var(--color-extra-light)
    color: var(--color-extra-dark)
    margin-right: 0
    margin-left: 8px
    float: right

  .recycle_indicator
    background: var(--notification-area)
    width: max-content
    height: 32px
    line-height: 32px
    padding: 0 8px
    margin-top: 8px