.tabbedContent
  width: 100%

  &--tabs
    display: flex
    width: 100%
    list-style: none

  &--tab
    width: 140px
    height: 38px
    padding: 10px 0 10px 16px
    cursor: pointer
    font-size: 14px

    &__active
      cursor: default

  &--content
    width: 100%
    margin-top: -1px
    padding: 20px

.tabbedContent__light
  .tabbedContent--tab__active
      border-bottom: 1px solid #006EA5

  .tabbedContent--content
    border-top: 1px solid #e6e6e6
    border-bottom: 1px solid #e6e6e6

.tabbedContent__filled
  .tabbedContent--tab
    background: #e6e6e6
    border: 1px solid #e6e6e6
    border-radius: 3px

    &__active
      border-bottom: 1px solid #FFF
      background: #fff

  .tabbedContent--content
    border: 1px solid #e6e6e6

.tabbedContent__unpaddedContent
  .tabbedContent--content
    padding: 0

.tabbedContent__fullWidthTabs
  .tabbedContent--tab
    flex-grow: 1