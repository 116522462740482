.section
  position: relative
  display: flex
  flex-direction: column
  padding: 16px
  margin-bottom: 16px
  border-radius: 8px
  width: 100%
  background-color: var(--online-background-color)
  border: solid 1px var(--online-border-color)

  .collapsed
    display: none;

  &--inline
    flex-direction: row
    justify-content: space-between
  
  &-content
    width: 100%
    display: flex
    flex-direction: column

    &--inline
      flex-direction: row

  .section-icon-wrapper
    width: 40px

    .collapse__icon
      height: 40px
      display: flex
      cursor: pointer
      transform: rotate(-90deg)

      svg
        display: inline-block
        margin: auto

      &--expanded
          transform: rotate(90deg)
