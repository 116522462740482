.form
  display: flex
  flex-direction: column
  & > div
    display: flex
    flex-direction: row
    flex-wrap: wrap
    width: auto
    margin-bottom: 8px
    align-items: center
  &-button-wrapper
    button
      margin-right: 8px