.search-box
  display: flex
  flex-wrap: wrap
  margin-bottom: 40px
  position: relative

  .input__wrapper
    flex-basis: auto
    margin-right: 24px

  .search-filter-wrapper
    position: relative
    margin-right: 24px

    .search-input
      width: 100%

      input
        width: 100%

    .search-input-icon
      position: absolute
      top: 3px
      right: 8px
      background: var(--input-background)
      width: 24px
      height: 24px
      margin: 0
      transition: var(--transition-slow)

    label
      z-index: 1
      color: var(--color-txt-light)
      font-size: 1rem
      font-weight: normal
      position: absolute
      pointer-events: none
      left: 8px
      top: 12px
      transition: all 0.2s ease
      width: 100%
      display: block

      &.active
        top: -10px
        transform: scale(.75)
        left: -10%
        display: block

  form
    flex: 1
    max-width: 15.15%

  .dropdown
    width: 170px

  .checkbox-extras-container
    position: relative
    display: flex
    justify-content: flex-start
    align-items: center

    .checkbox-container
      margin-right: 8px

.extra-form
  width: 100%
  display: flex
  justify-content: space-between
  margin-bottom: 10px
  column-gap: 8px

  > div
    width: 30%

    input
      width: 100%

  button
    width: 15%

.extras-list
  width 100%
  list-style: none

  &-title
    margin-bottom: 16px

  .extra-item
    display: flex
    justify-content: space-between
    align-items: center

    .extra-item-description
      display: flex
      justify-content: space-between
      width: 65%
      color: var(--color-txt-dark)
      border-bottom: 1px solid var(--color-extra-light)
      height 40px
      line-height: 30px
      margin: 8px 0px 0
      padding: 4px 8px

.no-extras-warning
  text-align: center

.extras-page-title
  margin-bottom: 2rem

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .search-box
    .checkbox-extras-container
      width: 30%

@media all and (max-width: 768px)

  .search-bar-box,
  .search-box
    width: 100%
    .checkbox-extras-container
      width: 30%