.sales-upload__template-download
  display: flex
  color: var(--color-txt-highlight)
  font-size: .85em

.sales-upload__report-section
  
  & > p
    margin-top: 0

  & > ul
    list-style: none
    margin-bottom: 1em
    
    li
      line-height: 2
      display: flex
      align-items: center


  .tabbedContent
    margin-bottom: 1em

    .tabbedContent--tab
      flex-grow: 1

    .tabbedContent--content
      padding: 0

  .sales-upload__table-inserted,
  .sales-upload__table-updated
    th
      text-align: left
    th:last-child
      text-align: center

  .sales-upload__report-actions
    display: flex
    justify-content: flex-end

    .button
      margin-right: 16px
      &:last-child
        margin-right: 0

.sales-upload__icon-alert, 
.sales-upload__icon-approved,
.sales-upload__icon-download
  height: 16px
  width: 16px
  margin-right: 8px
  color: var(--color-txt-highlight)

.sales-upload__icon-approved
  color: var(--greenalert-state)

.sales-upload__icon-alert
  color: var(--redalert-state)