.line-item
  display: flex
  flex: 1
  justify-content: space-between
  align-items: center
  position: relative
  min-height: 40px
  padding: 8px 8px
  margin: 0
  overflow: hidden
  text-overflow: ellipsis
  border-bottom: 1px solid var(--border-color)

  .label
    flex: 1
    white-space: normal
    align-items: center
    display: flex

  .value
    margin-left: 8px
    text-align: right
    overflow: hidden
    text-overflow: ellipsis

  .action-content
  .action-content:hover
  .action-content:focus
    display: flex
    justify-content: flex-end
    align-content: center
    width: 16px
    margin-left: 8px
    padding: 8px 0
    background: none

    img
      width: 16px

  &.borderless
    border-bottom: none

  &.thick
    border-bottom: 2px solid var(--border-color)

  &.separator
    border-bottom: 3px solid var(--border-color)

  &.filled
    background: var(--border-color)
    border: 0
    font-weight: var(--typography-bold)
&.filled
  background: var(--online-grey)
  border: 0
  font-weight: var(--typography-bold)
  &-dark
    background: #CBCBCB

&.bold
  font-weight: var(--typography-bold)

  &.bold
    font-weight: var(--typography-bold)

  &.bold-label
    .label
      font-weight: var(--typography-bold)

  &.title
    font-size: 1.2rem
    font-family: var(--typography-title)
    font-weight: var(--typography-bold)