.campaign-settings
  > .heading
    margin-bottom: 24px

    .button
      float: right

  .details-wrapper
    width: 50%
    margin-bottom: 30px

  #campaignForm
    margin-right: 10px
    display: inline-block
    .button-form__wrapper
      width: 100%

  .tabbedContent
    margin-top: 16px

    &--content
      padding: 16px

  .campaign-communications
    img
      width: 100%

  .campaign-cohort
    margin-top: 16px

.campaign-offer-actions
  
  display: flex
  gap: 8px

  h2
    flex: 1

#campaignForm.button-form
  > button
      margin-bottom: 16px