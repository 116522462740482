.deviceCodeForm
  max-width: 370px
  width: 100%
  position: relative
  flex-direction: column
  margin-top: 8px


  .deviceCodeForm__ref
    width: 100%
    height: 67px

    input
      width: 100%

    &--disabled
      input
        cursor: not-allowed

  .deviceCodeForm__code-select
    margin-top: 28px

  .deviceCodeForm__refType
    margin-top: 20px

  .button .device-code__button
    height: 32px

  .device-code__button
    width: 70%
    margin:10px auto 0 auto
    display:block

  &__button
    width: 100%

  .badges
    --badge-bg: var(--greenalert-state)
    --badge-border: var(--greenalert-state)
    position: absolute
    right: 8px
    bottom: 35px
    width: auto

.deviceCodeForm--has-helper .badges
  bottom: 75px