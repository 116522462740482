.input__input-helper
  display: flex
  color: var(--greenalert-state)
  padding-left: 8px
  margin: 4px 0 0
  min-height: 16px
  line-height: 16px
  font-size: 12px
  flex-direction: column
  flex-basis: 100%
  flex: 1
  flex-direction: inherit
  &.input__input-helper--bottom
    flex: auto;
    flex-direction: initial
    width: 100%

  &--error
    color: var(--redalert-state)

  .input__input-helper-icon
    display: inline-block
    margin-right: 4px
    width: 16px
    text-align: center
    font-weight: var(--online-typography-bold)
    font-size: 14px
    font-style: normal
    color: var(--input-background)
    background-color: var(--greenalert-state)
    border-radius: 50%

.dropdown--large-height
  & + .input__input-helper
    padding-left: 18px

.input__container--large-height
  & + .input__input-helper
    padding-left: 18px

@media all and (max-width: 768px)
  .input__input-helper
    flex: auto;
    flex-direction: initial
    width: 100%