.recycle-devices-summary
  display: flex
  flex-direction: column
  width: 100%
  &-values
    display: flex
    align-self: flex-end
    justify-content: flex-end
    flex-direction: column
    width: fit-content
    margin: 10px 0
    background: var(--border-color)

    > div
      display: flex
      justify-content: flex-end
      font-weight: var(--typography-bold)
      > div
        padding: 10px

      .total-label
        text-align: right
      .total-value
        min-width: 80px
        text-align: right
