.recycle-customer-info
  margin-top: 24px
  margin-bottom: 24px
  width: 30vb

  .marketingOptInToggle
    margin: 12px 0

    .checkbox__label
      margin-left: 0px
      line-height: 24px

  button
    width: 100%
    margin-top: 16px
  
  .recycle-disclaimer
    margin-top: 16px

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .recycle-customer-info
    width:66.66%

@media all and (max-width: 768px)

  .recycle-customer-info
    width: 100%