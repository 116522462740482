.blockEditor
  display: grid
  height: 100%
  grid-template-columns: 50%
  grid-auto-rows: max-content
  grid-gap: 32px
  grid-template-areas: 'title notifications'\
                       'editor preview'

  .blockTitle
    grid-area: title

  .blockIndicator
    grid-area: notifications
    background: var(--notification-area)
    width: max-content
    justify-self: end
    height: 32px
    line-height: 32px
    padding: 0 8px

    .blockIndicatorIcon
      vertical-align: sub
      margin-right: 8px

  .blockEditionArea
    grid-area: editor

    .blockForm
      display: flex
      flex-direction: column

      &--textArea
        textarea
          display: block
          width: 100%
          min-height: 596px
          padding: 5px
          overflow: auto
          outline: none
          box-shadow: none
          resize: none
          border: 2px solid var(--online-grey)
          border-bottom-left-radius: var(--border-radius)
          border-bottom-right-radius: var(--border-radius)


      &--actionButtons
        margin-top: 16px

        .button
          width: 100%

  .blockPreviewArea
    grid-area: preview

  .blockPreview
    width: 100%

  .bodyPreviewArea
    border: 0
    width: 100%
    height: 596px
