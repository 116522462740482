.discountForm

  .modal
    min-height: 50vh

    .modal-content
      min-height: 50vh

    .discount-form
      width: 100%
      display: flex
      justify-content: space-between
      padding: 8px 8px 8px 0

      label.active
        left: -20px

      > div
        min-width: 152px
        margin-right: 40px

        input
          width: 100%

        p
          margin-bottom: 0
          color: var(--redalert-state)

      button
        width: 30%

    .discount-list
      width 100%
      list-style: none
      padding: 8px 8px 8px 0

      .discount-item
        display: flex
        justify-content: space-between
        align-items: center

        .discount-details
          display: flex
          justify-content: space-between
          align-content: flex-start
          width: 75%
          color: var(--color-txt-dark)
          border-bottom: 1px solid var(--color-extra-light)
          min-height 32px
          margin: 8px 0px 0
          padding: 4px 8px

          span:first-child
            flex-grow: 1

          span:last-child
            width: 100px
            text-align: right

    .discount-total
      display: flex
      align-items: center
      justify-content: space-between
      flex: none
      position: relative
      width: 75%
      height: 40px
      margin-right: auto
      padding: 8px 8px
      margin-left: 0
      font-weight: var(--typography-title)
      background-color: var(--border-color)

      span:first-child
            flex-grow: 1

      span:last-child
        width: 33%
        text-align: right

      button
        display: none

  .product-summary
    .modal
      .line-item
        margin-left: 0
