.content-left
  .creditSignActions
    .button-wrapper
      display: flex
      margin-top: 8px
      .button
        display: block
        margin-right: 8px

  .deviceReferenceForm
    grid-area: extraContent

  .activateContract
    margin: 0 0 16px calc(24px + var(--item-status-circle-dimension))
    padding: 0 48px
    text-align: center

  .resend-button-selector
    cursor: pointer
    text-decoration: underline