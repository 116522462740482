.breadcrumbs
  flex: 1
  margin-top: .5rem
  margin-bottom: 8px
  font-size: 0.9em
  text-decoration: none
  color: var(--color-txt-dark)

  a
    cursor: pointer

  span
    font-family: var(--typography-title)
    font-weight: var(--typography-bold)