.system-page
  &__wrapper
    width: 100%
    position: fixed
    z-index: 1000
    top: 0
    right: 0
    display: grid
    grid-template-rows: calc(100vh - 81px)
    grid-column-gap: 16px
    background-color: white

  &__img
  &__info-box
    margin: auto
    padding: 16px
    padding: 16px

  &__img
    margin-right: 16px
    text-align: right 
    img
      width: 80%
      
  &__info-box
    margin-left: 16px

    h1
      font-size: 5rem
      line-height: 5rem
      margin: 0
      padding: 0

    h2
      font-size: 3rem
      line-height: 3rem
      margin: 0 0 2rem
      padding: 0

    p
      font-size: 1.5rem
      line-height: 2rem
      margin: 0 0 0

    span
      text-decoration: underline
      cursor: pointer

  &__footer
    grid-column: 1/3

  &__wrapper--with-header
    position: inherit
    z-index: inherit
    .system-page__info-box
      grid-column-start: none

@media all and (max-width: 768px)

  .system-page
    &__img
      width: 100%
      margin-right: 0
      text-align: right

      img
        width: 100%
    
    &__info-box
      width: 100%
      margin-left: 0
      text-align: left

@media all and (max-width: 760px)

  .system-page
    &__img
      display: none

    &__info-box
      grid-column: span 2
      width: 100%
      text-align: center
      margin-left: 0