.date-selector
  display: flex
  justify-content: center

  .date-text
    font: bold 1.5em Arial, Helvetica
    color: #404040
    align-self: center

  .date-button
    background-color: #f8f8f8
    border: 1px solid #c0c0c0
    border-radius: 5px
    font: 3em Arial, Helvetica
    width: 70px
    height: 50px
    line-height: 45px
    margin: 0px 20px
    text-decoration: none
    color: #808080
    box-shadow: 1px 2px 2px #d0d0d0

    &:hover
      background-color: #e0e0e0

    &:focus
      outline: 0
      background-color: #fafafa

.btndisabled
  color: #e0e0e0

.dateButtonDiv
  width: 100%
  align-content: center
  float: left

.not-active
  pointer-events: none
  cursor: default
  text-decoration: none
  color: #e0e0e0