.outcomes--multi
  width: 100%

  .outcomes-section
    .outcomes-selection
      display: flex
      flex-direction: row
      justify-content: flex-start

  .gift-card-container,
  .recycle-customer-info
    width: 267px