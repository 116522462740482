.services__steps__badge
    display: flex
    width: 100%
    margin-bottom: 24px
    .multistep
        flex 1
        margin-bottom: 0 !important
        h1 
            margin: 0 0 0

.services_intro
    margin: 0 0 24px
    padding: 16px 16px 0
    background: var(--background-extra-medium)
    p
        margin: 0 0 16px 0
        padding: 0 0 0 0
        span
            display: block
            font-weight: 700

.services-main
    width: 80%

.services-separator
   display: none

.services-main
    width: 100%

.services
    .services-block
        margin-bottom: 32px

    ul
        display: flex
        flex-wrap: wrap

        li
            list-style: none
            width: calc(20% - 8px)
            margin: 4px
            padding: 16px
            border-radius: var(--border-radius)
            background-color: var(--button-default)
            text-align: center
            font-family: var(--typography-body)
            font-size: 1rem
            color: var(--button-text-default)
        
            &:hover
                cursor: pointer
                background-color: var(--button-highlight)

            img
                width: 88px
                height: 88px

            div
                margin-bottom: 8px

            span
                display: block
                position: relative
                margin: 0 0 8px

                .spinner
                    right: -32px
                    top: -6px
            p
                margin: 0
                font-size: .85rem
                line-height: 1.2rem

.services-block
    flex: 1

    h3
        margin: 0 0 8px

.marketing-banner-horizontal
    height: 90px
    margin-bottom: 16px
    img 
        width: auto
        height: 90px

.marketing-banner-vertical
    display: flex
    flex-direction: column
    gap: 8px
    flex-wrap: wrap
    margin-top: -90px

    img
        width: 160px

.categories-spinner
    height: 64px
    width: 64px
    padding: 8px
    margin: 8px

.service-spinner-container
    display: flex
    justify-content: center
    align-items: center
    background: var(--background-extra-medium) 0% 0% no-repeat padding-box;
    width: 100%
    border-radius: var(--border-radius)
    min-height: 80px


/* ============================ Media Queries ============================ */

@media only screen and (max-width: 1080px)
    .services-main
        width: 100%
    .services-block
        flex-basis: 49%

@media only screen and (max-width: 768px)
    .services-main
        width: 100%
        .services-block
            flex-basis: 48%
    
    .marketing-banner-vertical
        margin-top: 40px

    .marketing-banner-horizontal
        height: auto
        img 
            width: 100%
            height: auto