.sale-summary
  width: 100%
  margin-bottom: 24px
  padding: 16px
  border: 1px solid var(--border-color)
  border-radius: var(--border-radius)

  &__table
    width: 100%
    margin-top: 24px
    border-collapse: collapse

    th
      padding: 8px
      background: var(--background-extra-medium)
      font-family: var(--typography-title)
      font-weight: var(--typography-bold)

      &:first-child
        max-width: fit-content
        text-align: left
     
    tr
      border-top: 1px solid var(--border-color)

    td
      padding: 8px
      vertical-align: top

      a
        color: var(--color-txt-dark)
      
      &.sale-summary__products
        padding: 0
        display: flex
        flex-align: row

        span
          line-height: 1.2rem
          padding: 8px 8px 8px 0

        img
          width: 40px
          object-fit: contain
          height: 40px
          margin: 8px
          vertical-align: middle

      &.sale-summary__prices
        padding: 8px

        p
          margin: 0
          height: 40px