.password-change
  &-title
    height: 28px
    width: 100%
  &-form
    width: 340px
    margin: 0 auto

    ul
      color: var(--color-txt-light)
      list-style-type: circle
      margin: 0
      padding: 0px 25px 10px
      line-height: 1.5

    .password-rules-title
      color: var(--color-txt-light)
      padding-left: 10px
      line-height: 1.5

    button
      width: 100%