.communication-form
  width: 66%

  h2
    margin-bottom 16px

  &__wrapper
    padding: 16px
    border: 1px solid var(--background-extra-medium)

  .form-button-wrapper
    margin-top 16px

    button
      width: fit-content