.tooltip
  .tooltip-title
    cursor: pointer

  .tooltip-description
    position: absolute
    top: 20px
    right: -1px
    z-index: 1
    min-width: 240px
    padding: 4px
    border-radius: var(--border-radius)
    font-size: .95rem
    line-height: 1.4rem
    color: var(--color-txt-extra-light)
    background-color: var(--redalert-state)
