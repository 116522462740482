.invoice-container
  padding-bottom: 16px

.invoice-info-container
  display: flex
  justify-content: space-between
  font-size: 16px
  margin: 8px 0 8px 0
  &__title
    font-family: var(--typography-title)

  &__entities
    display: flex
    flex-direction: column
    text-align: right
    span
      font-family: var(--typography-title)
