.sales-report-actions
  display: flex
  flex-direction: row
  justify-content: flex-end
  gap: 16px
  align-items: center

  .button
    margin-right: 16px
    &:last-child
      margin-right: 0

.sales-report-table
  thead
    th
      font-family: var(--typography-title)

.sales__forms
  .sales-form__search-container
    padding-right: 16px
    border-right: 2px solid var(--border-color)
  .sales-form__filter-container
    padding-left: 16px

@media all and (max-width: 1024px)
  .sales__forms
    flex-direction: column !important
    .sales-form__search-container
      padding-right: 0px
      border: none
    .sales-form__filter-container
      padding-left: 0px
    