.sales-form__filter-container
  width: 100%
  display: flex
  flex-direction: column

  p
    margin-top: 0

  .sales-form__filter
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: flex-end

    .sales-form
      &__reference
        max-width: 30%
        padding-right: 4px
      &__contract-status
        max-width: 30%
        padding: 0 4px
      &__date-range
        max-width: 40%
        padding-left: 4px
      &__store
        max-width: 50%
        margin-top: 8px
        padding-right: 4px

    .react-datepicker
      .react-datepicker__header
        .react-datepicker__month-select,
        .react-datepicker__year-select
          height: 24px
          margin: 8px 0

    .form-button-wrapper
      width: 50%
      margin-top: 8px
      padding-left: 4px
      display: flex
      align-items: flex-start
      justify-content: space-between

      button
        margin: 0
        width: calc(50% - 4px)