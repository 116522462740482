.toggle
  --toggle-width: 72px
  --toggle-height: 32px
  --toggle-padding: 2px
  --toggle-border-thickness: 2px
  --toggle-border-style: solid
  --toggle-off-bg: #FFF
  --toggle-on-bg: #FFF
  --toggle-disabled-bg: #FFF
  --toggle-off-fill: #000
  --toggle-on-fill: #000
  --toggle-disabled-fill: #CECECE
  --toggle-off-border-color: #CECECE
  --toggle-on-border-color: #000
  --toggle-disabled-border-color: #CECECE
  --toggle-disabled-opacity: 0.3
  --toggle-font-size: 15px
  --toggle-label-margin: 8px
  --toggle-animation-speed: .4s

  display: grid
  grid-template-rows: var(--toggle-height)
  grid-template-columns: var(--toggle-width) 1fr
  grid-column-gap: var(--toggle-label-margin)
  align-items: center
  font-size: var(--toggle-font-size)

  &__input
    opacity: 0
    width: 0
    height: 0

  &__switch
    position: relative
    width: var(--toggle-width)
    height: var(--toggle-height)

  &__slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: var(--toggle-off-bg)
    border-width: var(--toggle-border-thickness)
    border-color: var(--toggle-off-border-color)
    border-style: var(--toggle-border-style)
    transition: var(--toggle-animation-speed)
    border-radius: calc(var(--toggle-height) - calc(2 * (calc(var(--toggle-border-thickness) + var(--toggle-padding)))))

    &:after
      position: absolute
      content: ""
      height: calc(var(--toggle-height) - calc(2 * (calc(var(--toggle-border-thickness) + var(--toggle-padding)))))
      width: calc(var(--toggle-height) - calc(2 * (calc(var(--toggle-border-thickness) + var(--toggle-padding)))))
      left: var(--toggle-padding)
      bottom: var(--toggle-padding)
      background-color: var(--toggle-off-fill)
      transition: var(--toggle-animation-speed)
      border-radius: 50%

  &__label
    cursor: pointer

  &__input
    &:checked
      & + .toggle__slider
        background-color: var(--toggle-on-bg)
        border-color: var(--toggle-on-border-color)

        &:after
          background-color: var(--toggle-on-fill)
          transform: translateX(calc(var(--toggle-width) - calc(var(--toggle-height) + var(--toggle-padding))))

  &__input:disabled
    & + .toggle__slider
      cursor: default
      opacity: var(--toggle-disabled-opacity)
      background-color: var(--toggle-disabled-bg)
      border-color: var(--toggle-disabled-border-color)

      &:after
        opacity: var(--toggle-disabled-opacity)
        background-color: var(--toggle-disabled-fill)
        border-color: var(--toggle-disabled-border-color)