.scrolling-table__container
  position: relative
  max-width: 100%

  .scrolling-table__scroller
    overflow-x: scroll
    overflow-y: visible

    th:first-child
      border-left: 1px solid var(--color-header-top)
    th:last-child
      border-right: 1px solid var(--color-header-top)

.multiSelectActions__container
  display: flex
  justify-content: flex-end
  margin-bottom: 10px

  .multiSelectActions__button
    margin-left: 5px

.table
  word-break: break-word
  position: relative
  width: 100%
  margin: 0
  display: table
  table-layout: auto
  border-spacing: 0
  border-collapse: collapse

  &.table--word-break-normal
    word-break: normal

  &.table--overflowed
    position: initial
    width: initial
    display: inherit
    &.no-data
      width: 100%
      display: inline-table

  thead, tbody
    .stickyCol
      position: absolute
      width: 33%
      left: 0
      border-left: 1px solid var(--background-extra-medium)
      border-bottom: 1px solid var(--background-extra-medium)
      text-overflow: ellipsis
      overflow-x: hidden
      display: block
      white-space: nowrap

  thead
    text-align: center
    background: var(--table-color-header)
    color: var(--color-txt-extra-light)

    th.stickyCol
      background: var(--table-color-header)
      color: var(--color-txt-extra-light)

    tr:first-child
      th:first-child
        min-width: 2.2rem

    tr:nth-child(n+2)
      th
        padding: 8px 4px
        border: 1px solid var(--background-extra-light)
        border-bottom: 1px solid var(--background-extra-medium)

      th:first-child
        border-left: 1px solid var(--background-extra-medium)

      th:last-child
        border-right: 1px solid var(--background-extra-medium)

  tbody
    border: 1px solid var(--background-extra-medium)

  tr:nth-child(even),
  tr:nth-child(even) > th.stickyCol,
  tr:nth-child(even) > td.stickyCol
    background: var(--background-extra-medium)

    td
      border: 1px solid var(--background-extra-light)
      border-bottom: 1px solid var(--background-extra-medium)

      &:first-child
        border-bottom: 1px solid var(--background-extra-medium)
        border-left: 1px solid var(--background-extra-medium)

      &:last-child
        border-right: 1px solid var(--background-extra-medium)
        border-bottom: 1px solid var(--background-extra-medium)

  tr a
    margin-right: 10px
    cursor: pointer

  th
    position: relative
    padding: 8px 8px
    font-size: .90rem
    font-weight: var(--typography-normal)
    white-space: nowrap


    input
      width: 95%
      height: 30px
      border: 1px solid var(--input-background)
      transition: var(--transition-fast)

      &:focus
        border: 1px solid var(--color-txt-dark)

  td
    position: relative
    padding: 8px 8px
    clear: both

    span[class^='status-']
      display: inline-block
      min-width: 5rem
      padding: 2px 4px
      border-radius: var(--border-radius-extrasmall)
      text-align: center
      font-size: .85rem
      color: var(--color-txt-extra-light)
      text-shadow: 0 1px 1px var(--color-txt-dark)
      background: var(--greenalert-state)

    span[class='status-pending']
    span[class='status-future']
    span[class='status-early-swap']
    span[class='status-created']
    span[class='status-booked']
      color: var(--yellowalert-state-txt)
      text-shadow: 0 0 0
      background: var(--yellowalert-state)

    span[class='status-archived']
    span[class='status-blocked']
    span[class='status-disabled']
    span[class='status-inactive']
    span[class='status-failed']
    span[class='status-no-swap']
    span[class='status-refused']
    span[class='status-cancelled']
      background: var(--redalert-state)

    span[class='status-withdrawn']
      background: var(--inactive-state)

    span[class^='boolean-']
      display: inline-block
      width: 1rem
      height: 1rem
      border-radius: var(--border-radius-extrasmall)

    span[class='boolean-false']
      background: var(--yellowalert-state)

    span[class='boolean-true']
      background: var(--greenalert-state)

    span[class='multiLine']
      display: block
      width: 100%
      word-break: break-all
      text-overflow: ellipsis

    span[class='multiLine']:nth-child(even)
      font-style: italic
      padding-top: 8px

    ul
      list-style: none

    img
      width: 35px
      height: 35px
      object-fit: contain
      cursor: pointer

    .taglist
      display: flex
      justify-content: center
      flex-wrap: wrap
      list-style: none
      min-width: 200px
      max-width: 400px

      .tag
        float: left
        background: #ccc
        padding: 2px 5px
        border-radius: var(--border-radius-small)
        margin: 2px 5px

    .modal
      .modal-content
        img
          width: 55%
          height: 55%

  td:last-child
    text-align: right

  td > .button
    margin-right: 5px
    &:last-child
      margin-right: 0

  td.left
    text-align: left

  td.center
    text-align: center

  td.right
    text-align: right
    white-space: nowrap

  td.wrap
    white-space: normal

  td.no-data
    position: relative
    height: 24px

    .spinner
      top: 0
      left: calc(50% - 40px)

  .editable-table-row
    display: flex
    padding: 0

    .table-cell
      flex-grow: 1

      .button
        margin-right: 5px
  .table-bool-icon
    width: 24px
    height: 24px

    &--approved
      color: var(--greenalert-state)
    &--alert
      color: var(--redalert-state)