.payment-plans-select
  width: 100%
  margin-bottom: 16px

  .payment-plans-options
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    margin-bottom: 0

    li
      list-style: none
      height: fit-content
      flex-basis: calc(20% - 8px)
      margin: 4px;
      padding: 16px
      background: var(--background-extra-light)
      border: 2px solid var(--border-color)
      border-radius: var(--border-radius)
      text-align: left
      font-family: var(--typography-body)
      color: var(--color-txt-dark)

      .details
        display: block

        .item-name
          padding: 0 0

        .item-id
          display: none

        .item-price-difference span
          font-weight: normal

      &:hover
        border: 2px solid var(--border-color-active)
        background: var(--background-extra-light)
        color: var(--color-txt-dark)

      &.selected
        border: 2px solid var(--border-color-active)
        background: var(--background-extra-light)
        color: var(--color-txt-dark)

/* ============================ Media Queries ============================ */
@media all and (max-width: 1024px)
  .payment-plans-select
    h2
      min-width: 50%

    .payment-plans-options
      min-width: 50%

@media all and (max-width: 768px)
  .payment-plans-select
    .payment-plans-options
      li
        width: 22.5%
        max-width: 22.5%