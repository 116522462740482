.list--big-buttons
  list-style: none
  display: flex
  flex-wrap: wrap
  flex-direction: row
  justify-content: center
  width: 100%
  margin: 8% auto 8% auto
  padding: 0 0

  li
    list-style: none
    width: 20%
    height: 12rem
    margin: 1rem

    .button
      font-size: 1rem
      display: block

      span
        line-height: 11rem
      img+span
        line-height: normal

.renderPropList
  list-style: none

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)
  .list--big-buttons
    flex-direction: row

    li
      width: 33.33%

@media all and (max-width: 768px)
  .list--big-buttons
    flex-direction: column

    li
      width: 50%
      margin-left: auto
      margin-right: auto

/* ============================ Browser Hacks ============================ */

/*Microsoft Edge*/
@supports (-ms-ime-align:auto)
  .list--big-buttons
    justify-content: space-between