.recyle-diagnostic__modal
  .modal-content
    .heading.center
      width: 100%

    .recyle-diagnostic__iframe
      width: 100%
      height: 450px
      border: none

    .modal-buttons
      .button:first-child
        margin: auto

    .recyle-diagnostic__buttons
      .recyle-diagnostic__button:first-child
        margin-right: 32px