.collectors-filter
  display: flex
  margin-bottom: 24px

  form
    display: flex
    justify-content: space-between
    width: 100%

    .collection-form-collector
      display: flex

      .dropdown
        flex: initial
        width: 152px
        margin-left: 24px
        position: relative

        &:first-child
          margin-left: 0

    .collection-form-dates
      display: flex
      position: relative
      cursor: pointer

    .datepicker
      position: relative
      cursor: pointer
      margin-left: 20px

.organise-collection-btn
  margin-right: 24px
  white-space: nowrap

.button
  &.create-collection-btn
    position: absolute
    top: 67px
    right: 24px
    white-space: nowrap

    &.disabled
      display: none

.collectors-filter+table
  thead
    tr:nth-child(even)
      display: none

    th:last-child
      width: auto

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .collectors-filter
    margin-bottom: 0

    form.organise-collection-form
      justify-content: flex-end
      width: 100%

      > div
        justify-content: flex-end
        margin-bottom: 24px

        > div
          margin: 0 0 0 24px

          &:first-child
            margin-left: 0

      .collection-form-dates
        margin-left: 24px

        > div
          width: 152px
          margin-left: 0

          input
            width: 100%

@media all and (max-width: 768px)

  form.organise-collection-form
    flex-flow: column

@media print

  body
    -webkit-print-color-adjust: exact !important

  header
  .footer
  .btn-print
  .pagination
    display: none

  #app main > div[role='group']
    padding: 0 0 0

  #admin > main .content
    border: 0

  .table
    thead
      th
        height: 4rem

    tbody
      border: 0

    tr
      border-bottom: 1px solid var(--background-extra-medium)
      break-inside: avoid

      td
        white-space: initial

      td:first-child
        height: 5rem
        border: 0

        svg
          height: 5rem

    tr:nth-child(even)
      background: transparent

      td
      td:first-child
      td:last-child
        border: 0

@page
  size: A4
  margin: 1cm 1cm 1cm 1cm