#admin .modal .admin-user
  max-width: 400px

  input, .dropdown
    max-width: 237px
    height: 40px

  p
    padding-top: 10px
    padding-left: 6px

  ul
    margin: 10px 0 20px 0

    .dropdown
      margin: 0 0 1rem
      position: relative

      .dropdown-list
        max-height: 35vh

    li
      width: 100%
      display: inline-flex
      flex-direction: row
      justify-content: space-between
      margin: 0

      .input__wrapper
        width: 100%

      .add-store,
      .set-default
        width: 150px
        padding: 0 0
        font-size: 0.9rem
        text-align: center

      .line-item
        max-width: 237px

      .set-default
        height: 40px
        margin-top: 13px
        background: var(--button-default)
        opacity: 1

      .default-store
        opacity: 1
        color: var(--button-default)
        background: var(--background-extra-light)

  button:last-child
    float: right
