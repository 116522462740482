.quote-search-form
  width: 100%
  display: grid
  column-gap: 20px
  row-gap: 20px
  grid-template-columns: 1fr 1fr 1fr
  grid-template-rows: auto

  & > div
    min-height: 62px

    input
      width: 100%

  button
    width: calc(50% - (6px / 2))
    justify-self: start
    padding: 0

.quote-search-results-table
  td:nth-child(2),
  td:nth-child(3)
    white-space: pre