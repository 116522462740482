.device-filters-form
  display: grid
  grid-template-columns: repeat(13, 1fr)
  grid-template-rows: repeat(2, 40px)
  column-gap: 8px
  row-gap: 24px
  
  .form-search-icon
    display: none

  .form-button-wrapper
    grid-column-end: span 13
    margin-left: auto
    margin-right: 0px

  button
    grid-column: span 2
    white-space: nowrap

  .form-submit-mobile-btn
    grid-column: span 1
    display: none
    padding: 12px

    img
      height: 24px

  &__manufacturer-filter,
  &__category-filter,
  &__family-filter
    grid-column: span 2

  &__device-filter
    grid-column: span 5

  &__date-filter
    grid-column: span 2

    label
      width: auto
      top: 10px

    label.active
      top: 3px
      left: -6px
      
    .react-datepicker-wrapper
      display: grid

      .react-datepicker__input-container
        input[type='text']
          width: 100%
          padding: 16px 24px 0 6px



    &__filter-form
      grid-template-columns: repeat(6, 1fr)

      .form-button-wrapper
        grid-column-end: auto
        grid-template-columns: repeat(3, 1fr)
        display: grid
        column-gap: 8px
        button
          width: 100%
          margin: 0
          grid-column: span 1
        .form-search-text
          display: none
        .form-search-icon
          display: initial
          grid-column: span 1

@media all and (max-width: 1024px)
  .device-filters-form
    grid-template-columns: repeat(6, 1fr)

    &__manufacturer-filter,
    &__category-filter,
    &__family-filter
      grid-column: span 2

    &__device-filter
      grid-column: span 4

    &__date-filter
      grid-column: span 1

    .form-button-wrapper
      grid-column-end: auto
      grid-template-columns: repeat(3, 1fr)
      display: grid
      column-gap: 8px
      button
        width: 100%
        margin: 0
        grid-column: span 1
      .form-search-text
        display: none
      .form-search-icon
        display: initial
        grid-column: span 1

  