.button.payment-info-btn
  width: 40px
  height: 40px
  background: #fff
  padding: 0

.payment-info-btn-icon
  margin-top: 2px
  width: 32px
  height: 32px
  fill: var(--info-icon)

  &:hover
    fill: var(--info-icon-white)

.payment-info-anchor
  cursor: pointer
  color: var( --button-default)
  text-decoration: underline
  text-align: right
  display: block
  padding-top: 4px

.modal-background.payment-info  .modal-content
  text-align: left
