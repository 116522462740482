.trade-summary
  width: 100%

  .summary-product
    margin-bottom: 24px

    .item-details
      border-bottom: none

    dd, dt
      border-bottom: 1px solid var(--border-color)
      padding: 0 8px

    .action-buttons-container
      display: flex
      justify-content flex-end
      align-items: center
      height: 32px
      width: 32px

      .button
        width: 100%
        height: 100%
        padding: 0

    .product-img
      img
        width: 100%
        height: auto

    .description-list
      border-top: 1px solid var(--border-color)

      .line-item:first-child
        .label
          font-family: var(--typography-body)

      .line-item:last-child
        .value
          white-space: normal

    .filled
      font-size: 1.2rem
      font-family: var(--typography-title)
      border-bottom: 2px solid var(--color-txt-extra-light)

.add-device
  margin-left: 80px

.quote-customer
  border-top: 1px solid var(--border-color)
  dd
    text-align: left
    justify-content: flex-start