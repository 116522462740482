.upload-report
  .modal-content
    align-items: flex-start

  .upload-stats
    list-style: none

    li
      font-weight: var(--typography-bold)
      margin: 3px 0px

  .error-list
    width: 100%
    max-height: 400px
    overflow: auto
    list-style: none
    margin-top: 20px
    padding: 10px
    border-top: 1px solid var(--border-color-extra)

    .error
      margin: 10px 0
      font-weight: var(--typography-bold)

      p
        margin: 5px 0px
        padding: 0