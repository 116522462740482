.deviceDisownmentChecks
  display: flex
  flex-direction: row
  margin: 16px 0
  padding: 16px 0
  justify-content: space-between
  border-bottom: solid 1px var(--online-border-color)
  border-top: solid 1px var(--online-border-color)

  &__disclaimers
    display: flex

    .disclaimer-msg
      margin-right: 8px

  .sim-status-disclaimer
    min-width: calc(50% - 8px)
    display: flex
    flex-direction: column
    margin-left: 8px

  .disclaimer-msg
    display: flex
    flex-direction: row
    line-height: 24px

  span + span:last-child:before
    content: " / "

  .disclaimer-icon
    width: 24px
    height: 24px
    color: var(--online-dropdown-background)
    background-color: var(--greenalert-state)
    border-radius: 12px
    margin-right: 8px

  .disclaimer-icon--warning
    background-color: var(--yellowalert-state)

  .checked-icon,
  .exclamation-icon
    padding: 4px

  .unlocked-icon,
  .locked-icon
    padding: 2px

  .restartRecycleButton
    margin-top: 16px

.disowned-disclaimer
  width: 100%
  margin: 16px 0

.sim-status-radio-buttons
  width: 30%
  margin-top: 8px

// Multi cart
.deviceDisownment
  display: flex
  flex-direction: column

  &__indicators
    display: flex
    flex-direction: row

    .disclaimer-icon
      width: 22px
      height: 22px
      border-radius: 50%
      margin-right: 8px

      &--success
        color: var(--greenalert-state)
        border: 2px solid var(--greenalert-state)

      &--warning
        color: var(--redalert-state)
        border: 2px solid var(--redalert-state)

    .disclaimer-msg
      margin-right: 16px

      span
        position: relative
        bottom: 6px

  &__disclaimers
    color: var(--redalert-state)
    max-width: 741px
    line-height: 1.2rem
    padding-bottom: 16px
    margin-top: 8px