.footer
  position: relative
  width: 100%
  line-height: 2rem
  text-align: center
  background: var(--background-extra-light)

  &.main
    border-top: 1px solid var(--color-extra-light)

    .footer-inner-wrapper
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      position: relative
      width: 100%
      max-width: var(--layout-max-width)
      padding: 0 24px
      height: var(--layout-footer-height)
      margin: auto

      .button
        z-index: 150
        min-width: 180px

        &.next
          margin-left: auto

  .branding
    position: absolute
    left: 0
    right: 0

    img
      width: auto
      height: 24px
      vertical-align: middle

  .copyright
    font-size: 0.85rem

  .build
    position: absolute
    bottom: 0px
    left: 0px
    right: 0px
    font-size: 11px
    color: #606060
