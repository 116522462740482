.product-list-wrapper
  width: 100%

.product-list
  list-style: none
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  width: 100%
  padding: 0

.product-item
  position: relative
  width: 20%
  list-style: none
  border-left: 1px solid var(--color-extra-light)
  border-right: 1px solid var(--color-extra-light)
  border-bottom: 1px solid var(--color-extra-light)
  margin-left: -1px
  padding: 0 24px
  cursor: pointer
  
  .monthly-price-extension
      font-size: 1rem
      font-weight: var(--typography-normal)

  &:first-child
  &:nth-of-type(5n+1)
  &:nth-last-child(5n+1)
    border-left: 0

  &:last-child
  &:nth-of-type(5n)
    border-right: 0

  .badges
    position absolute
    z-index: 10
    left: 4px
    top: 4px
    display: flex
    flex-direction: column
    gap: 2px

    .badge
      display: block
      width: fit-content
      padding: 4px
      background: var(--color-promotions)
      color: var( --color-white)
      font-size: .75rem
      white-space: nowrap
      border: 0
      
      &:first-child
      &:last-child
        border-radius: 4px

  .item-img-wrapper
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
    height: 214px
    margin-top: 24px

    img
      display: block
      width: 100%
      height: auto
      max-height: 240px
      margin: 24px auto

  section
    padding-bottom: 4.5rem

    p
      text-align: center
      margin: 3px

    p.big
      font-size: 1.2rem
      font-family: var(--typography-title)
      font-weight: var(--typography-bold)

      &:first-child
        margin: 0 0 0

      &:last-child
        margin: 0 0 0
        padding: 4px 24px 0
        font-family: var(--typography-title)

  .button
    opacity: 0
    position: absolute
    left: 0
    right: 0
    bottom: 8px
    z-index: 150
    width: 80%
    margin: auto

  &.selected
    .button
      background: var(--button-highlight)

.product-item:hover,
.product-item.selected
  .badge, img
    filter: grayscale(75%)
    opacity: .55
    transition: var(--transition-fast)

  .button
    opacity: 1
    transition: var(--transition-slow)
    transition-delay: 0s
  
.product-list-catalog-page-title
    margin-bottom: 2rem

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .product-list-wrapper
    .search-bar-box
        width: 25%
        padding-left: 1rem

    .product-item
      width: 25%

      &:first-child
      &:nth-of-type(4n+1)
      &:nth-last-child(4n+1)
        border-left: 0
        border-right: 1px solid var(--color-extra-light)

      &:last-child
      &:nth-of-type(4n)
        border-right: 0

      .button
        opacity: 100

@media all and (max-width: 768px)

  .product-list-wrapper
    .search-bar-box
        width: 33%
        padding-left: 1rem

    .product-item
      width: 33.33%

      &:first-child
      &:nth-of-type(3n+1)
      &:nth-of-type(3n-1)
      &:nth-last-child(3n+1)
        border-left: 0
        border-right: 1px solid var(--color-extra-light)

      &:last-child
      &:nth-of-type(3n)
        border-right: 0