.heading
  font-family: var(--online-typography-semibold)
  font-weight: normal

h1.heading
  font-size: 1.71rem // 24px
  line-height: 2.36rem  // 33px

h2.heading
  font-size: 1.29rem // 18px
  line-height: 1.71rem // 24px

h3.heading
  font-size: 1rem // 14px
  line-height: 1.35rem // 19px

.heading.left 
  text-align: left;

.heading.right 
  text-align: right;

.heading.center 
  text-align: center;

.heading.borderTop
  margin: 15px 0 0 0
  padding: 15px 0 0 0
  border-top: 1px solid var(--online-border-color)
