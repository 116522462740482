.item
  display: flex
  flex-basis: 33.333%
  padding: 1rem
  cursor: pointer
  border: 2px solid rgba(255,255,255, 0)

  &.selected
    border: 2px solid var(--border-color)
    border-radius: var(--border-radius)

  .item-image-wrapper
    display: flex
    justify-content: center
    align-items: center
    width: 80px
    height: 80px
    margin-right: 1rem

    img
      width: auto
      height: 100%

  .selection-indicator
    flex-shrink: 0
    display: block
    width: 40px
    height: 40px
    margin-right: 1rem
    margin-left: 0.5rem
    border-radius: 100%
    background: var(--background-extra-medium)

  .details
    display: flex
    flex-direction: column
    justify-content: flex-start
    flex: 1
    padding-right: 1rem

    p
      margin: 0 0 4px

      &:last-child
        margin: 0 0 0

    .item-name
      font-size: 1rem
      font-family: var(--typography-title)
      font-weight: var(--typography-bold)

    .item-id
      font-size: 0.85rem
      color: var(--color-txt-light)
      
    .item-price
      font-size: 0.85rem
      
    .item-price--strikethrough
      font-size: 0.85rem
      color: var(--inactive-state)
      margin-left: 4px
      text-decoration: line-through

    .item-price-difference
      margin-top: 5px
      font-size: 1.3rem
      font-family: var(--typography-title)
      font-weight: var(--typography-bold)

      span
        padding-left: 4px
        font-size: 1rem
        font-weight: var(--typography-body)

    .item-price-difference.highlight
      color: var(--color-txt-highlight)

.item.button
  padding: 0

  .details
    width: 100%

.item.circle:hover:not(.disabled)
.item.circle.selected
  .selection-indicator
    background: var(--color-extra-dark)
    border: 6px solid var(--background-extra-medium)

.item.disabled
  cursor: initial
  opacity: 0.5

&.extra
  &:hover
    border: 2px solid var(--border-color)
    border-radius: var(--border-radius)

  .details
    justify-content: flex-start

.stock-level
  position: relative
  .stock-level-marker
    height: 8px
    width: 8px
    border-radius: 32px
    display: inline-block
    margin-right: 4px
  .stock-level-label
    position: absolute
    left: 16px
    bottom: 0
  .stock-level-marker-green
    background: var(--greenalert-state)
  .stock-level-marker-red
    background: var(--redalert-state)
  .stock-level-marker-yellow
    background: var(--yellowalert-state)