.dashboard
  display: grid
  width: 100%
  font: 0.9em Arial,Helvetica, Geneva, sans-serif
  padding: 5px
  text-align: center
  grid-template-columns: 1fr
  grid-gap: 10px

.ServiceTable
  width: 100%
  background: #f8f8f8;
  border-collapse: separate;

  text-align: left
  color: #003031
  font: 1em Calibri, Arial, Helvetica, Geneva, sans-serif
  table-layout: fixed

  caption
    margin-top: 10px
    text-align: left
  a
    color: #444444

.MethodTable
  width: 100%
  background: #f8f8f8
  text-align: left
  color: #003031
  font: 1em Calibri, Arial, Helvetica, Geneva, sans-serif
  table-layout: fixed
  border-collapse: separate;
  margin: 1px;

  a
    color: #003031

  td
    padding: 2px 5px 2px 5px
    font-size: 1em
    line-height: 16px
    text-align: center

  th
    padding: 2px 5px 2px 5px
    font-weight: normal
    background-color: #08BED5
    font-size: 1em
    color: #FFFFFF
    letter-spacing: 1px

    &:first-child
      text-align: left
      padding-left: 5px

  tr
    text-align: center

.ServiceTable_caption
  color: #003031
  letter-spacing: 1px
  font: 1.5em Calibri, Arial, Helvetica, Geneva, sans-serif

.ServiceTable_innercaption
  color: #003031
  letter-spacing: 1px
  font: 1.2em Calibri, Arial, Helvetica, Geneva, sans-serif

.ServiceTable_captionsmallText
  font-size: 0.8em
  color: #003031
  letter-spacing: 1px
  font: 0.8em Calibri, Arial, Helvetica, Geneva, sans-serif

.ServiceTable_innerHeader
  font-size: 0.9em
  font-style: italic
  background-color: #f8f8f8
  line-height: 20px

.ServiceTable_none
  font-size: 1.0em
  font-style: italic
  line-height: 20px
  text-align: center
  vertical-align: top

.ServiceTable_salesCounter
  float: left
  height: 18px
  box-shadow: 1px 2px 2px #d0d0d0

.ServiceTable_smallText
  margin: 0px 3px 1px 3px
  padding: 1px
  font-size: 0.9em

.ServiceTable_stackTrace
  margin: 0px 3px 1px 3px
  padding: 1px
  font-size: 0.8em
  text-align: left
  white-space: pre

.ServiceTable_wrap
  white-space: normal

.ServiceTable_smallTextI
  margin: 0px 3px 1px 3px
  padding: 1px
  font-size: 0.9em
  font-style: italic

.stripe_even 
  background-color: #f0f0f0
.stripe_odd
  background-color: #ffffff

.background_gray
  opacity: 0.5
.background_green
  background-color: #a9dfbf
.background_yellow
  background-color: #f9e79f
.background_red
  background-color: #e6b0aa