.file-upload-form
  display: flex
  flex-direction: row
  width: 100%
  max-width: calc(var(--online-layout-max-width) / 2)
  margin-bottom: 1em   

  .file-upload-form__buttons
    margin-left: 16px
    button + button
      margin-left: 8px

  &--vertical
    flex-direction: column

    .file-upload-form__buttons
      margin: auto