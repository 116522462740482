.attachment__editor__form
    min-width 100%

.attachment__editor__header
    margin-bottom: 10px

.attachment__editor__area
    border: 0

.attachment__editor__area_text
    overflow: auto
    outline: none
    box-shadow: none
    resize: none
    margin-top: 5px
    margin-bottom: 5px

.attachment__editor__area_preview
    width: 100%
    height: 570px
    min-height: 570px
    overflow: auto
    outline: none
    box-shadow: none
    resize: none
    margin-top: 5px
    margin-bottom: 5px
    border: 0

.attachment__editor__area__actions
    width: 100%
    display: flex
    justify-content: flex-start
    gap: 10px
    :last-child
        background-color: #DC143C
