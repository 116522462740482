.tradecart-device
  &__diagnostic-test
    display: flex
    justify-content: space-between

  &__diagnostic-icon
    width: 12px
    height: 12px
    border-radius: 50%
    margin-right: 8px
    padding: 2px

  &__diagnostic-icon__success
    color: var(--color-white)
    background: var(--greenalert-state)

  &__diagnostic-icon__warning
    background: var(--yellowalert-state)

  &__diagnostic-success,
  &__diagnostic-warning
    padding: 16px
    border-radius: 4px

    strong
      font-family: var(--typography-title)

    span, strong
      display: inline-block
      vertical-align: middle
      line-height: normal

  &__diagnostic-success
    background-color: var(--greenalert-transparent-state)
    border: 1px solid var(--greenalert-state)

  &__diagnostic-warning
    background-color: var(--yellowalert-transparent-state)
    border: 1px solid var(--yellowalert-state)

  &__diagnostic-button
    width: 136px
    min-width: 136px
    margin-left: 24px
  
  &__diagnostic-modal
    .modal
      width: 428px
      height: 584px

  &__diagnostic-iframe
    width: 100%
    height: 512px
    border: none