.attachment__header
  display: grid
  margin-bottom: 20px
  grid-template-columns: 50%
  grid-auto-rows: max-content
  grid-gap: 32px
  grid-template-areas: 'title notifications'

  .attachment__header__title
    grid-area: title

  .attachment__header__notification
    grid-area: notifications

.attachment__notification
    background: var(--notification-area)
    width: max-content
    justify-self: end
    height: 32px
    line-height: 32px
    padding: 0 8px
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius)

    .attachment__notification__icon
      vertical-align: sub
      margin-right: 8px
        

.attachments__table_actions_container
  display: flex
  justify-content: flex-end
  gap: 5px

.attachments__dropdown
    min-width: 200px
    padding: 5px
    text-align: center

.attachments__disclaimer
    width: 55% 
    align-self: flex-end
    padding-bottom: 20px
