header
  background: var(--color-header-top)
  color: var(--color-txt-light)
  clear: both

  .header-inner-container
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    max-width: var(--layout-max-width)
    margin: 0 auto
    padding: 16px 24px
    clear: both
    color: var(--color-txt-extra-light)

    .dropdown
      width: 150px
      .dropdown-input
        border: none
      .dropdown-input__icon
        color: var(--color-txt-extra-light)
      .dropdown-list
        color: initial
      & + .input__input-helper
        display: none

    .header-inner-container-divider
      width: 1px
      height: 24px
      margin: 0 16px
      background: var(--background-extra-light)

    > div
      display: flex
      justify-content: space-between
      align-items: center

    .whitelabel-logo
      cursor: pointer
      display: block
      width: auto
      height: 24px

      img
        height: 100%

    .store-menu, .language-switcher, .settings-menu
      border-bottom: none

    .language-switcher
      .select-menu__control
        min-width: 90px

  .navigation
    background: var(--color-header-bottom)

    a
      text-decoration: none
      color: var(--color-extra-light)
      cursor: pointer

      &:hover
        color: var(--color-txt-light)
        text-decoration: none
        cursor: pointer

  .option-container
    .select-menu__control
      min-width: 164px
      text-overflow: ellipsis

      .select-menu__value-container
        .select-menu__single-value
          width: calc(100% - 24px)
          color: var(--color-txt-extra-light)

.user-menu
  position: relative
  top: 4px
  width: 24px
  height: 24px
  margin-left: 8px
  fill: var(--user-menu-icon-color)
.settings-menu
  position: relative
  line-height: 2rem
  z-index: 1
  cursor: pointer

  img
    position relative
    top: .25rem
    height: 20px
    margin-left: 16px

  figure
    width: 20px
    height: 20px
    cursor: pointer

    svg
      width: 20px
      height: 20px
      fill: var(--color-txt-light)
      transition: var(--transition-slow)

      #user-icon
        display: block

      #arrow-icon
        display: none

  figure:hover
    svg
      #user-icon
        display: none

      #arrow-icon
        display: block

  ul
    list-style-type: none
    position: absolute
    padding: 0
    margin: 0
    top: 34px
    right: 0
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius)
    color: var(--color-txt-dark)
    background: var(--input-background)
    text-align: right

    li
      display: block
      min-width: 160px
      padding: 0 8px
      color: var(--color-txt-dark)
      cursor: pointer

      &:hover
        text-decoration: none
        background: rgba(1, 1, 1, 0.1)
      a
        color: var(--color-txt-dark)
        text-decoration: none
        
/* ============================ Media Queries ============================ */

@media all and (min-width: 768px)
  .navigation
    .header-inner-container
      justify-content: flex-end

      .dropdown-menu, > a:last-child
        margin-left: 2rem
