.slideshow
  width: 100%
  margin: 0

  .slideshow__content
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: center

    .slideshow__prev-btn,
    .slideshow__next-btn
      display: none

    .slideshow__slider
      position: relative
      white-space: nowrap
      min-width: 100%
      height: 100%
      display: flex
      overflow-x: scroll
      margin: 0 var(--slideshow-btn-size, 76px)
      scroll-behavior: smooth
      scroll-snap-type: x mandatory
      scrollbar-width: none
      -webkit-overflow-scrolling: touch
      -ms-overflow-style: none
      &::-webkit-scrollbar
        width: 0
        height: 0

      .slideshow__slide
        position: relative
        flex-shrink: 0
        width: 100%
        background: transparent
        margin: 0
        scroll-snap-align: start

        picture
          display: block

          .slideshow__slide-image
            width: 100%
            height: auto
            pointer-events: none

        .slideshow__slide-caption
          position: absolute
          bottom: 0
          left: 0
          right: 0
          padding: 1em
          background: rgba(233,233,232, 0.7)
          text-align: center

    .slideshow__thumbs-list
      display: flex
      align-items: center
      max-width: 100%
      padding: 0
      margin: .5em 0
      list-style-type: none
      overflow-x: scroll
      scroll-behavior: smooth
      scrollbar-width: none
      -webkit-overflow-scrolling: touch
      -ms-overflow-style: none
      &::-webkit-scrollbar
        width: 0
        height: 0

      .slideshow__thumb
        width: 1.2em
        height: 1.2em
        margin: 0 .15em
        padding: .15em
        overflow: hidden
        border-radius: 50%
        border: solid 2px var(--color-extra-light, '#e9e9e9')

        .slideshow__thumb-btn
          display: block
          width: 100%
          height: 100%
          border-radius: 50%
          .slideshow__thumb-image
            display: none
        &--selected
          .slideshow__thumb-btn
            background: var(--color-txt-highlight, '#0060a4')


@media all and (min-width: 768px)
  .modal .slideshow 
    width: 90%

  .slideshow
    .slideshow__content

      .slideshow__prev-btn,
      .slideshow__next-btn
        display: block
        appearance: none
        position: absolute
        top: 50%
        transform: translate3d(0, -50%, 0)
        z-index: 4
        width: auto
        height: var(--slideshow-btn-size, 76px)
        padding: 0 0
        border: 0
        border-radius: var(--border-radius, 4px)
        font-size: var(--slideshow-btn-size, 76px)
        color: var(--color-extra-light, '#e9e9e9')
        background: none
        outline: none
        cursor: pointer
        &:hover,
        &:active,
        &:focus
          background: none
          outline: none
          opacity: 0.5
      .slideshow__prev-btn
        left: 0
      .slideshow__next-btn
        right: 0
        svg
          transform: rotate(180deg)

      .slideshow__slider
        min-width: calc(100%-(var(--slideshow-btn-size, 76px)*2))

      .slideshow__thumbs-list
        .slideshow__thumb
          overflow: visible
          width: auto
          height: auto
          padding: calc(var(--slideshow-thumb-size, 50px)*0.1)
          list-style: none
          border: none
          border-radius: 4px
          .slideshow__thumb-btn
            .slideshow__thumb-image
              display: block
              width: var(--slideshow-thumb-size, 50px)
              height: auto
          &--selected
            border: solid 2px var(--color-extra-light, '#e9e9e9')
            .slideshow__thumb-btn
              background: none
              .slideshow__thumb-image
                width: calc(var(--slideshow-thumb-size, 50px) * 1.25)
  
    &--with-thumbnails
      .slideshow__content
        .slideshow__prev-btn,
        .slideshow__next-btn
          top: calc((100% - var(--slideshow-thumb-size, 50px))/2)
          
