.sale-main-buttons,

.sale-header
  width: 100%
  display: flex
  justify-content: space-between
  margin-bottom: 24px

.badges
  text-transform: uppercase
  position: relative
  top: -3px

  & .badge
   font-size: .75rem

  > .sale__header__overstatus
    margin-left: 8px
    color: var(--color-txt-dark)
    font-family: var(--typography-body)
    font-size: .85rem
    --badge-bg: var(--yellowalert-state)
    
    &--active
      --badge-bg: var(--greenalert-state)
    &--pending-activation
      --badge-bg: var(--yellowalert-state)
    
    &--refused,
    &--finished,
    &--swap,
    &--historic,
    &--cancelled,
    &--returned
      --badge-bg: var(--redalert-state)
      
    &--withdrawn
      --badge-bg: var(--inactive-state)

.sale-title__anchors
  display: flex
  line-height: 33px

  .sale-title__anchor
    cursor: pointer
    margin-left: 8px
    text-decoration: underline

.sale-buttons
  width: 100%
  display: flex
  justify-content: flex-end
  margin-bottom: 16px

  button
    margin-left: 8px

.badges
  > .sale-page__badge 
    margin-left: 8px
    color: var(--color-white)
    font-family: var(--typography-body)
    --badge-bg: var(--yellowalert-state)
    --badge-border: transparent
    
    &--active
      --badge-bg: var(--greenalert-state)
      
    &--refused,
    &--finished,
    &--swap,
    &--historic,
    &--cancelled,
    &--returned
      --badge-bg: var(--redalert-state)
      
    &--withdrawn
      --badge-bg: var(--inactive-state)

.stakeholders
  width: 100%
  display: flex
  flex-wrap: wrap

  h2
    width: 100%

  .stakeholder-details
    float: left
    list-style: none
    height: calc(100% - 16px)
    padding: 0px 16px
    border-right: 1px solid var(--border-color)
    flex: 1
    position: relative
    word-break: break-all

    li:first-child
      .button
        height: 24px
        position: absolute
        right: 16px
        top: 12px
        line-height: 6px

    li
      margin: 4px 0 0

    &:nth-child(2)
      padding-left: 0

    &:last-child
      border-right: 0
    
    & h3
      margin-top: 8px

    & p
      margin: 0

.contract-details
  width: 100%

  .contract-title
    margin-bottom: 16px

    .contract-status
      float: right

      .contract-status-label
        margin-left: 8px

        &.label-active
          color: var(--greenalert-state)

        &.label-cancelled, &.label-refused
          color: var(--redalert-state)

        &.label-withdrawn
          color: var(--inactive-state)

  .contract-actions
    width: 100%
    clear: both
    overflow: auto
    margin: 24px 0

    & > .button
      float: left
      margin: 4px
    
    .contract-actions--right
      float: right
  
    .contract-actions__print
      padding: 8px

    .print-img
      width: 24px

  .timeline-container
    clear: left
    margin-top: 150px

  .contract-specs
    display: grid
    grid-gap: 24px
    grid-template-columns: repeat(auto-fill, minmax(512px, 1fr))
    width: 100%

    .line-item
      align-items: baseline
      overflow: visible
      text-overflow: ""
      white-space: normal
      min-height: 32px
      padding: 4px 8px

      .label
        flex: 1
        white-space: normal
        font-family: var(--typography-title)
        font-weight: var(--typography-bold)

      .value
        flex: 2
        overflow: visible
        text-overflow: ellipsis
        > ul
          list-style: none 
          li
            text-align: right

    .detail-list
      .action-content
        display: none

.sale-actions__table
  th
    text-align: left

@media all and (max-width: 900px)
  .sale-title__anchors
    line-height: initial