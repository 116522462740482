.postpone-contract-modal
  text-align: left

  h2
    margin-bottom: 16px

  textarea
    margin: 16px 0

  .postpone-contract-actions
    display: flex
    flex-direction: row
    justify-content: space-between
