.dashboard
  display: grid
  width: 100%
  font: 0.9em Arial,Helvetica, Geneva, sans-serif
  padding: 5px
  text-align: center
  grid-template-columns: 1fr
  grid-gap: 10px

.StatsTable
  width: 100%
  background: #ffffff
  text-align: left
  color: #444444
  font: 1em Arial, Helvetica, Geneva, sans-serif
  border: 1px solid #d0d0d0
  border-radius: 4px
  table-layout: fixed

  td
    padding: 2px 5px 2px 5px
    border-top: 1px solid #ffffff
    border-bottom :1px solid #e0e0e0
    border-left: 1px solid #e0e0e0
    font-size: 1em
    line-height: 18px
    text-align: center
    vertical-align: middle
    overflow: hidden

    &.StatsTable_rotate 
      line-height: 95px

      div
        -moz-transform: rotate(-90.0deg)
        -o-transform: rotate(-90.0deg)
        -webkit-transform: rotate(-90.0deg)
        transform: rotate(-90.0deg)
        border: 0px
        display: inline-block
        white-space: nowrap
        font-style: normal

  th
    padding: 5px 3px 5px 3px
    border-top: 1px solid #fafafa
    border-bottom: 1px solid #e0e0e0
    border-left: 0px
    font-weight: normal
    background-color: #f0f0f0
    font-size: 1em
    color: #444444
    letter-spacing: 1px
    text-align: center
    vertical-align: middle

    &:first-child
      text-align: left
      padding-left: 5px

    &.StatsTable_rotate
      -moz-transform: rotate(-90.0deg)
      -o-transform: rotate(-90.0deg)
      -webkit-transform: rotate(-90.0deg)
      transform: rotate(-90.0deg)
      border: 0px
      padding: 0px
      font-size: 0.9em
      white-space: nowrap
  tr
    text-align: center
    padding-left: 5px

    &:first-child
      th
        &:first-child
          border-top-left-radius: 4px

        &:last-child
          border-top-right-radius: 4px


    &:last-child
      td
        border-bottom: 0

      &:first-child
        border-bottom-left-radius: 3px

      &:last-child
        border-bottom-right-radius: 3px

@media screen and (max-width: 1024px) 
  table.wideTable
    display: none
    width:100%
  table.narrowTable
    display: table
    width:100%
  
@media screen and (min-width: 1024px) 
  table.wideTable
    display: table
    width:100%
  table.narrowTable
    display: none
    width:100%

.StatsTable_caption
  font-size: 1.2em
  color: #444444
  letter-spacing: 1px
  font: 1.3em Arial, Helvetica, Geneva, sans-serif

.StatsTable_rotatedHeader
  font-size: 0.9em
  background-color: #f0f0f0
  line-height: 100px
  text-align: center

.StatsTable_innerHeader
  font-size: 0.8em
  font-style: italic
  background-color: #f0f0f0
  line-height: 20px

.StatsTable_innerHeaderLarge
  font-size: 1em
  background-color: #f0f0f0
  line-height: 20px

.StatsTable_none
  font-size: 1.1em
  font-style: italic
  line-height: 20px
  text-align: center

.StatsTable_salesCounter
  float: left
  height: 18px
  box-shadow: 1px 2px 2px #d0d0d0

.StatsTable_smallText
  margin: 0px 3px 1px 3px
  padding: 1px
  font-size: 0.9em

.StatsTable_wrap
  white-space: normal

.StatsTable_smallTextI
  margin: 0px 3px 1px 3px
  padding: 1px
  font-size: 0.9em
  font-style: italic

.stripe_even 
  background-color: #f8f8f8
.stripe_odd
  background-color: #ffffff

.red
  background-color: #ff6666

.yellow
  background-color: #ffdf80

.green
  background-color: #c4ffd2

.textRed
  color: red

.textGreen
  color: green

.fullwidth
  width:100%
