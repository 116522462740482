.collectionsWrapper
  width: 100%
  height: 100%
  padding-bottom: 200px

.collectors-organise-filter
  flex-direction: initial
  margin-bottom: 24px
  .input__wrapper
    flex-basis: auto

  .dropdown
    width: 145px
    margin-right: 20px

  .datepicker
    position: relative
    cursor: pointer
    margin-right: 20px

.collector-rules
  position: absolute
  top: 76px
  right: 24px
  color: var(--redalert-state)

.button
  &.create-collection-btn
    position: absolute
    top: 67px
    right: 24px
    white-space: nowrap

    &.disabled
      display: none

.create-collection-btn+table
  thead
    tr:nth-child(even)
      display: none

form.admin-collections
  width: 50%
  max-width: 400px !important

  & > div
    margin-bottom: 1rem

  input, .button
    width: 100%

  .button
    margin-top: 24px

  .collectionDateTimeContainer
    margin-top: 4px
    max-width: 400px
    display: flex
    align-items: flex-start
    gap: 32px

    .react-datepicker
      button.react-datepicker__navigation
        width: 32px
        margin-top: auto

      &__input-container
        padding-top: 8px

    .react-datepicker__input-container
      input
        padding: 0 8px !important

    .dropdown.dropdown--medium-height
      padding-top: 8px

      .dropdown-input
        padding: 0px 40px 0px 8px
        line-height: 40px
        height: 40px

    .dropdown-list:not(.dropdown-list--open)
      position: fixed

    .input__floating-label
      color: var(--online-dark-grey)
    
    .input__floating-label.active
      z-index: 0
      left: 8px
      opacity: .75
      top: 0
      font-size: .8rem
      transform: none

a.collection-url
  color: var(--color-txt-dark)

/* ============================ Media Queries ============================ */

@media all and (max-width: 768px)

  .collectors-organise-filter
    justify-content: space-between

  .create-collection-btn
  .collector-rules
    position: relative
    top: 0
    right: 0
    margin-bottom: 24px
    float: right

@media print

  body
    -webkit-print-color-adjust: exact !important

  header
  .footer
  .btn-print
  .pagination
    display: none

  #app main > div[role='group']
    padding: 0 0 0

  #admin > main .content
    border: 0

  .table
    thead
      th
        height: 4rem

    tbody
      border: 0

    tr
      border-bottom: 1px solid var(--background-extra-medium)
      break-inside: avoid

      td
        white-space: initial

      td:first-child
        height: 5rem
        border: 0

        svg
          height: 5rem

    tr:nth-child(even)
      background: transparent

      td
      td:first-child
      td:last-child
        border: 0

@page
  size: A4
  margin: 1cm 1cm 1cm 1cm