.loader
  position: fixed
  top: 0
  left: 0
  z-index: 9999
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.9)

  .triple-spinner
    display: block
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 340px
    height: 340px
    margin: auto
    border-radius: 50%
    border: 4px solid transparent
    border-top: 4px solid #1F5D91
    animation: spin 4s linear infinite

  .triple-spinner::before, .triple-spinner::after
    content: ''
    position: absolute
    border-radius: 50%
    border: 4px solid transparent

  .triple-spinner::before
    top: 5px
    left: 5px
    right: 5px
    bottom: 5px
    border-top-color: #58A0D8
    animation: spin 3.5s linear infinite

  .triple-spinner::after
    top: 15px
    left: 15px
    right: 15px
    bottom: 15px
    border-top-color: #fff
    animation: spin 1.75s linear infinite

  @keyframes spin
    from
      transform: rotate(0deg)

    to
      transform: rotate(360deg)

  @keyframes spin
    from
      transform: rotate(0deg)

    to
      transform: rotate(360deg)

  .loader-title
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    color: #fff
    text-align: center
    margin: auto
    width: 100px
    height: 20px
    padding-top: 420px

.loader.processing
  .slideshow
    position: absolute
    color: transparent
    width: 60px
    height: 60px
    margin: 0
    padding: 0
    top: 50%
    left: 50%
    transform: translate(-30px, -30px)
    background-image: url('assets/images/processing_sprite.svg')
    animation-name: processingLoaderAnimation
    animation-duration: 2s
    animation-delay: 0
    animation-timing-function: steps(1, start)
    animation-iteration-count: infinite
    animation-fill-mode: forwards


@keyframes processingLoaderAnimation {
  0% { background-position: 0px 0px }
  33% { background-position: -60px 0px }
  66% { background-position: -120px 0px }
  100% { background-position: -180px 0px }
}