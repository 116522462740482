.collection-details
  display: flex
  flex-wrap: nowrap
  flex-flow: column

  h1
    margin-bottom: 8px
  
  &__btn-courier
    position: absolute
    padding: 0 8px
    right: 64px
    height: 32px

  &__btn-print
    position: absolute
    padding: 0
    right: 24px
    height: 32px

    img
      width: 32px

  .collection-details-dates
    width: 372px
    top: -3rem
    position: relative
    margin-left: auto

    .label
      font-weight: var(--typography-bold)
    .line-item
      border: none

  .store-list
    width: 40%
    margin: 8px 0 24px -8px

    .line-item
      min-height: 2rem

      .label
        flex: inherit
        white-space: nowrap

  table
    thead
      tr:nth-child(even)
        display: none

    tbody
      tr
        td:first-child
          padding: 1px 8px 0
          display: flex
          align-content: center
          justify-content: center

        svg
          height 48px

a.collection-url
  color: var(--color-txt-dark)

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .collection-details .store-list
    width: 50%

@media all and (max-width: 768px)

  .collection-details .store-list
    width: 65%

@media print

  body
    -webkit-print-color-adjust: exact !important

  header
  .footer
  .btn-print
  .pagination
    display: none

  #app main > div[role='group']
    padding: 0 0 0

  #admin > main .content
    border: 0

  .table
    thead
      th
        height: 4rem

    tbody
      border: 0

    tr
      border-bottom: 1px solid var(--background-extra-medium)
      break-inside: avoid

      td
        white-space: initial

      td:first-child
        height: 5rem
        border: 0

        svg
          height: 5rem

    tr:nth-child(even)
      background: transparent

      td
      td:first-child
      td:last-child
        border: 0

@page
  size: A4
  margin: 1cm 1cm 1cm 1cm