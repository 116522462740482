.sales-form__search-container  
  width: 100%
  display: flex
  flex-direction: column

  p
    margin-top: 0

  .sales-form__search
    display: grid
    grid-row-gap: 8px
    grid-column-gap: 8px
    grid-template-columns: 1fr 1fr

    .form-button-wrapper
      align-items: flex-start
      justify-content: space-between

      button
        margin: 0
        width: calc(50% - 4px)