.cart-list-title
  margin: 0
  width: 100%

.cart-list-orders
  width: 100%
  border-top: 2px solid var(--border-color)

  .cart-item
    height: auto

    .label
      padding: 0

      .cart-item-img-wrapper
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        margin: 0 24px 0 0;
        padding: 8px 0;

        img
          display: block
          width: 80px
          height: auto
          padding: 0
  
  .cart-item-unit-price-none
    border: none
    width: 96px
    text-align: right

  .cart-item-unit-price
    border: 2px solid var(--border-color)
    width: 96px
    text-align: right

.cart-list-discounts
  width: 100%

  .value
    width: 33%
    display: flex
    justify-content: flex-end
    font-family: var(--typography-title)

    span
      width: 50%
      text-align: right

  .action-content
  .action-content:hover
  .action-content:focus
    margin-left: 24px

  .line-item:last-child
    border-bottom-width: 2px

  &.small
    margin-left: 0

    .line-item:last-child
      border-bottom-width: 2px

    .value
      width: 50%

.cart-list-summary
  width: 100%
  margin: 0 0 24px 0
  border-top: 2px solid var(--border-color)
  font-family: var(--typography-title)
  font-weight: var(--typography-bold)

  .action-content
  .action-content:hover
  .action-content:focus
    margin-left: 24px

  .filled
    font-size: 1.2rem

  .value
    width: 33%
    display: flex
    justify-content: flex-end

    span
      width: 50%
      text-align: right

  &.small
    margin-left: 0
    border-top: 0

    .value
      width: 50%
      margin: 0 0 0

  .modal-content
    .discount-form
    .dropdown
    .discount-item
      font-family: var(--typography-body)

.cart-list-action-btns
  display: flex
  .cart-list-add-discount
    margin: 16px 16px 16px 8px



/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .cart-list-discounts
    margin-left: 0

    .value
      width: calc(30% - -24px)

  .cart-list-add-discount
    margin-left: 8px

  .cart-list-summary
    margin-left: 0

    .value
      width: calc(30% - -24px)