.deviceReferenceForm
  max-width: 370px
  position: relative
  flex-direction: column
  margin-top: 8px
  margin-bottom: 16px

  .deviceReferenceForm__input
    width: 100%
    height: 67px
    margin-top: 16px

    input
      width: 100%

    &--disabled
      input
        cursor: not-allowed

  .button .device-reference__button
    height: 32px

  .device-reference__button
    width: 100%

  &__button
    width: 100%

  .badges
    --badge-bg: var(--greenalert-state)
    --badge-border: var(--greenalert-state)
    position: absolute
    right: 8px
    bottom: 35px
    width: auto

.deviceReferenceForm--has-helper .badges
  bottom: 75px