.dropdown
  flex: 1
  position: relative
  outline: none
  width: 100%

  &--disabled
    pointer-events: none
    opacity: 0.5

  .dropdown-hidden-input
    display: none

  .dropdown__floating-label
    color: var(--online-dark-grey)
    padding: 0 18px
    position: absolute
    top: 20px
    left: 0
    user-select: none
    transition: font-size .25, top .25s, opacity .25s ease-in-out
    transform-origin: 0 0

    &--has-value,
    &--active
      z-index: 0
      opacity: 0.75
      top: 4px
      font-size: 0.8em

    &--hidden
      display: none

    &--outside^[1..-1]--has-value,
    &--outside^[1..-1]--active
      top: -16px

  .dropdown-input
    width: 100%
    height: 56px
    padding: 20px 36px 20px 16px
    border-radius: var(--online-border-radius)
    border: 2px solid var(--online-border-color)
    cursor: pointer
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &--focus:not(.dropdown-input--disabled)
      border-color: var(--online-border-color-focus)

    &--fixed
      line-height: 0.9

    .dropdown-input__icon
      position: absolute
      right: 0
      top: 50%
      transform: translate(0, -50%) rotate(-90deg)
      height: 28px
      padding: 8px
      width: 56px
      color: var(--color-txt-dark)

      &--expanded
        transform: translate(0, -50%) rotate(90deg)

      &-clear
        right: 20px
        padding: 9px

        &:hover
            color: var(--online-border-color)

    &--error
      border-color: var(--redalert-state)

  &--clearable
      .dropdown-input
        padding-right: 56px

  &--multiple
    .dropdown-list
      .dropdown-list__item
        padding-right: 24px
        padding-left: 36px
        
        &::before
          position: absolute
          left: 6px
          top: 8px
          content: ""
          border: 2px solid var(--online-mid-grey)
          width: 20px
          height: 20px
          border-radius: 2px
          background-color: var(--online-dropdown-background)

        svg
          color: transparent
          left: 18px
          width: 20px

        &--selected
          &::before
            background-color: var(--online-dropdown-item-selected-background)
          svg
            color: inherit

  &--medium-height
    .dropdown__floating-label
      padding-left: 8px
      top: 11px

    .dropdown__floating-label--has-value,
    .dropdown__floating-label--active
      top: 0px
      width: auto

    .dropdown-input
      height: 40px
      padding: 16px 24px 0 6px

      .dropdown-input__icon
        width: 40px
        right: 0px

        &-clear
          right: 20px

    &.dropdown--clearable
      .dropdown-input
        padding-right: 48px
    
    .dropdown-list
      &--open-top
        bottom: 48px

      .dropdown-list__item
        padding-left: 16px

        &--selected
          padding-right: 48px
          svg
            right: 12px

  &--medium-height.dropdown--multiple
    .dropdown-list
      .dropdown-list__item
        padding-left: 36px

        &::before
          left: 6px

        svg
          left: 8px

  &--no-radius
    .dropdown-input
      border-radius: 0

    .dropdown-list
      .dropdown-list__item
        padding-left: 16px

  &--no-radius.dropdown--medium-height
    .dropdown-list
      .dropdown-list__item
        padding-left: 16px

  &--no-radius.dropdown--medium-height.dropdown--multiple
    .dropdown-list
      .dropdown-list__item
        padding-left: 36px

  &--underline
    .dropdown-input
      padding: 20px 40px 20px 18px
      border: 0
      border-radius: 0
      border-bottom: 2px solid var(--online-border-color)

    .dropdown__floating-label
      &--outside.dropdown__floating-label--has-value,
      &--outside.dropdown__floating-label--active
        top: -11px

  &--underline.dropdown--medium-height
    .dropdown-input
      padding: 20px 40px 20px 8px
      line-height: 0.6

  &--underline.dropdown--medium-height.dropdown--clearable
      .dropdown-input
        padding-right: 50px


  &--rounded
    .dropdown-input
      padding-right: 16px
      padding-left: 16px
      border-radius: 56px

    .dropdown__floating-label
      padding-left: 22px

      &--has-value,
      &--active
        padding-left: 18px

      &--has-value^[1..-1]--outside,
      &--active^[1..-1]--outside
        padding-left: 21px
    
    .dropdown-list
      .dropdown-list__item
        padding-left: 16px

  &--no-radius.dropdown--multiple,
  &--underline.dropdown--multiple,
  &--rounded.dropdown--multiple
    .dropdown-list
      .dropdown-list__item
        padding-left: 46px

  &--rounded.dropdown--medium-height.dropdown--multiple
    .dropdown-list__item
        padding-left: 46px

        &::before
          left: 16px

        svg
          left: 18px

  &--half-width
    width: 50%

  &--third-width
    width: 33%
  
  &--left-anchor
    .dropdown-list
      left: 0

  &--right-anchor
    .dropdown-list
      right: 0

.dropdown-list
  width: auto
  overflow-y: auto
  overflow-x: hidden
  margin-top: 5px
  list-style-type: none
  border-radius: var(--online-border-radius)
  border: 2px solid var(--online-border-color)
  background-color: var(--online-dropdown-background)
  position: absolute
  z-index: 2
  visibility: hidden
  max-height: 40vh
  outline: none

  &--open:not(.dropdown-list--disabled)
    visibility: visible
    border-color: var(--online-dark-grey)

  &--open-top
    bottom: 64px

  .dropdown-list__item
    height: 2.85rem
    line-height: 2.85rem
    padding-left: 24px
    padding-right: 24px
    background-color: var(--online-dropdown-item-background)
    outline: none
    position: relative
    white-space: nowrap;

    &:hover
      cursor: default

    &--selected
      padding-right: 38px
      svg
        position: absolute
        right: 19px
        height: 2.85rem

    &--active
      background-color: var(--online-dropdown-hover)

  &--mouse-disabled
    .dropdown-list__item
      pointer-events: none

.dropdown-input__helper
  color: var(--greenalert-state)
  padding-left: 18px
  margin: 4px 0 0
  height: 16px
  line-height: 16px
  font-size: 12px

  &--error
    color: var(--redalert-state)

  .dropdown-input__helper-icon
    display: inline-block
    margin-right: 4px
    width: 16px
    text-align: center
    font-weight: var(--online-typography-bold)
    font-size: 14px
    font-style: normal
    color: var(--input-background)
    background-color: var(--greenalert-state)
    border-radius: 50%