.add__serial__numbers__content
    width: 100%
    margin: 0 0 24px
    padding: 16px 16px 0
    p
        margin: 0 0 24px
        padding: 16px 16px 0
        > span
            font-weight: 700
    section
        display: flex
        flex-direction: column
        padding: 16px 0px
        > span
            position relative
            text-align: left;
            letter-spacing: 0px
            margin: 0
            padding-bottom: 16px
        > ul
            list-style: none
            display: flex
            flex-direction: column
            border-radius: var(--border-radius)
            border: 1px solid var(--color-extra-light)
            padding: 8px
            li
                > div
                    display: flex
                    justify-content: center
                    align-items: center
                    ul 
                        padding-top: 8px
                    > span 
                        position relative
                        text-align: left;
                        letter-spacing: 0px
                        margin: 0
                        padding-right: 16px
                    input
                        flex: 1
                    div
                        padding-left: 16px
                        > img
                            width: 32px
                            height: 32px


