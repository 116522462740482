.modal-background
  position: fixed
  left: 0
  top: 0
  z-index: 9998
  padding: 24px
  width: 100%
  height: 100%
  background-color: rgba(#343434, 0.9)
  &.scrollable
    .modal-content
      overflow-y: scroll

.modal
  position: absolute
  top: 50%
  left: 50%
  z-index: 9999
  padding: 24px
  width: 100%
  max-width: 768px
  transform: translate(-50%, -50%)
  height: auto
  max-height: 98vh
  background-color: var(--background-extra-light)

  .modal-close-button
    height: 24px
    float: right
    cursor: pointer

  .modal-content
    display: flex
    align-items: center
    flex-direction: column
    width: 100%
    margin: 2rem auto
    max-height: calc(90vh - 24px)
    min-height: fit-content
    overflow-y: auto

    .title
      align-self: flex-start
      margin-bottom: 2rem

    .heading
      align-self: flex-start

    p
      margin: 2px

    & > ul
      list-style-position: inside

      li
        margin-bottom: 5px

    & .line-item
        min-height: 24px

    & .bold-label
        font-family: var(--typography-title)

      .payment-details .button
        display: none

  .modal-buttons
    width: 300px
    margin-top: 24px
    display: flex

    .button
      margin-left: var(--button-spacing)

    .button:first-child
      margin-left: 0

  .modal-buttons.confirmation
    justify-content: center

  .modal-buttons.list
    justify-content: flex-end

  .modal-list
    list-style-position: inside

    li
      margin-bottom: .75rem

      &:last-child
        margin-bottom: 0

#admin
  .modal
    img.edit-device
      height: 300px

    h2 + form
      margin-top: 2rem

    form
      width: 100%
      max-width: 320px
      padding-top: 5px

      > div
          margin-bottom: 1rem

          input
            width: 100%
            height: 40px

          .select-menu__dropdown-indicator svg
            fill: var(--background-extra-dark)

      .datepicker
        .react-datepicker-wrapper, .react-datepicker__input-container
          width: 100%
          input
            width: 100%
            height: 40px
            padding: 16px 24px 0 6px
        label.active
          top: 0
        .react-datepicker-popper
          z-index: 2         
            
      .dropdown.undefined
        width: 50%
        text-align: left

      & > ul
        list-style: none

        li
          margin: 24px 0

          input
            float: left

      &.add-device-id

        > div
          margin-bottom: 24px

        li
          display: flex
          flex-wrap: wrap
          flex-direction: row
          margin: 0 0 8px

          &:last-child
            margin-bottom: 24px

          div
            flex-grow: 1
            padding-right: 1rem

            input
              width: 100%
              float: none

            p
              margin-bottom: 0
              color: var(--redalert-state)

          button
            &.small
              margin-top: 8px
              margin-left: auto
              float: none

        button
            &:last-child
              float: right
    
    .form--large
      max-width: 640px
.trade-search
  .modal
    ul
      margin: 24px 0
      padding: 0 24px
      line-height: 2rem

.extra-actions + .modal-background
  .modal
    .modal-content
      overflow: visible


/* ============================ Media Queries ============================ */
  @media all and (max-width: 768px)
    .modal
      max-width: 720px
    
    .modal-background.full  
      .modal
        max-width: 720px
        height: 98%
        width: 95%
        border-radius: 5px
        
        .title
          margin-bottom: 8px

