
.file-input__wrapper
  height: auto
  display: flex
  flex-direction: column
  flex-basis: 100%

  .file-input__container
    width: 100%
    position: relative
    flex: 1
    border-radius: var(--online-border-radius)
    border: 2px solid var(--online-border-color)

    .file-input__input
      padding-left: 8px
      width: 100%
      height 100%
      border: none
      color: var(--color-txt-dark)
      background: transparent
      display: block
      color: transparent

      &::file-selector-button
        color: transparent
        background: transparent
        border: none

      &::-ms-browse
        color: transparent
        background: transparent
        border: none

      &:hover
        cursor: pointer

      &::-webkit-file-upload-button
        display: none

      &:focus
        outline: 0
        box-shadow: 0

    .file-input__value
      position: absolute
      top: 0
      left: 0
      padding: 8px 6px
      width: 100%
      height: 100%
      overflow: hidden
      text-overflow: ellipsis
      white-space:nowrap

    .file-input__floating-label
      width: auto
      padding: 0
      position: absolute
      z-index: auto
      left: 16px
      top: 9px
      user-select: none
      transition: font-size .25s, top .25s, opacity .25s ease-in-out
      transform-origin: 0 0

      &--active
        top: 12px
        z-index: 0
        opacity: 0.75
        font-size: 0.8em

      &--active + .file-input__input
        padding-top: 14px

    .file-input__floating-label
      color: var(--online-dark-grey)

    &--focused
      border: 2px solid var(--online-border-color-focus)

    &--disabled
      opacity: 0.5
      background-color: var(--online-light-grey)

    &--error
      border: 2px solid var(--redalert-state)

      .file-input__input
        border: 0

    // size rules

    &--medium-height
      height: 40px

      .file-input__input
        padding: 15px 24px 0 6px
      .file-input__value
        padding: 17px 24px 0 6px

      .file-input__floating-label
        left: 8px

        &--active
          top: 2px
          left: 0
          padding-left: 6px

      .file-input__input-img
        top: 7px

    // variant styles

    &--underline
      border-radius: 0
      border: 0
      border-bottom: 2px solid var(--online-border-color)

      .file-input__floating-label
        top: 10px

        &--active
          top: -10px
          left: 0
          padding-left: 7px

      .file-input__input
        border: 0

      .file-input__value
        padding-top: 10px

    &--underline^[1..-1]--focused
      .file-input__input
        border: 0

    &--underline^[1..-1]--focused
      border-bottom: 2px solid var(--online-border-color-focus)

    &--underline^[1..-1]--error
      border: 0
      border-bottom: 2px solid var(--redalert-state)

  &--error
    color: var(--redalert-state)