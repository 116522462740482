.login-wrapper
  display: flex
  justify-content: space-between
  width: 100vw
  height: 100vh

.login-content
  height: 100%
  width: 50%
  padding: 24px 24px
  position: relative
  display: flex
  flex-direction: column
  align-items: center

  .language-switcher
    width: 144px
    align-self: flex-start
    border-bottom: none

    .select-menu__dropdown-indicator svg
      fill: var(--color-extra-dark)

    .select-menu__menu-list
      border: 0
      border-color: var(--border-color)

  .whitelabel-logo
    position: absolute
    top: 30px
    right: 30px
    height: 32px

  .container
    margin: auto
    width: 100%
    max-width: 375px

  h1
    text-align: center

  p
    padding: 0 8px
    color: var(--color-txt-dark)

  .sso-button
    width: 100%
    margin-top: 15px

  .auth-form
    margin-bottom: 24px

    & > div
      margin-top: 20px

    input
      width: 100%

    .input-container:last-child
      margin-bottom: 8px

    button
      width: 100%

  .login-link
    position: relative
    display: inline-block
    margin-top: -8px
    margin-bottom: 24px
    color: var(--color-txt-light)
    text-decoration: underline

    &:hover
        text-decoration: none
        cursor: pointer

  .footer-inner-wrapper
    .branding
      position: relative

.login-gradient
  width: 50%
  height: 100%
  background: var(--login-gradient)

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)
  .login-content
    width: 70%

  .login-gradient
    width: 30%

@media all and (max-width: 768px)
  .login-wrapper
    height: auto

  .login-content
    width: 100%
    height 100vh

    .whitelabel-logo
      align-self: flex-end
      margin-bottom: auto

    .container
      position: absolute
      top: 25%
      right: 0
      left: 0

    .footer
      position: fixed
      bottom: 1rem

  .login-gradient
    display: none