.details-title
  width: 100%
  background: var(--background-extra-medium)
  padding: 10px
  margin-bottom: 0

.details
  width: 100%
  display: flex
  &--small
    .line-item
      min-height: 24px
      padding: 0 4px

  img
    display: block
    width: 60px
    height: 100px
    margin: 10px 20px
    object-fit: contain

  .detail-list
    width: 100%

    .line-item:last-child
      border-bottom: 0px
