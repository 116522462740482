.dropdown-menu
  position: relative
  cursor: pointer

  > div
    position: relative
    padding-right: 1.5rem

  img
    width: 1.1rem
    position: absolute
    top: 1px
    right : 0px
    transform: rotate(-90deg)

  ul
    list-style-type: none
    position: absolute
    z-index: 10
    top: 32px
    right: 0
    min-width: 12rem
    padding: 0
    margin: 0
    border: 0
    border-radius: 0
    background: var(--color-header-bottom)
    border: 0 solid var(--background-extra-medium)
    text-align: left

    li
      border-width:  0
      border-style: solid
      border-color: transparent
      cursor: pointer
      line-height: 2.2rem
      white-space: nowrap

      a
        display: block
        padding: 8px 8px
        color: var(--color-txt-extra-light)
        text-decoration: none
        border: 1px solid var(--color-header-bottom)

        &:hover
                background: var(--color-txt-extra-light)
                color: var(--color-txt-dark)

    &:hover
          color: var(--color-txt-light)
