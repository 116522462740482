.notification-wrapper
  position: fixed
  z-index: 10000
  left: 0
  right: 0
  max-width: 70%
  margin-left: auto
  margin-right: auto
  padding: 24px

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)
  .notification-wrapper
    max-width: 100%