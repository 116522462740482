.creditAcceptanceRate
  float: right
  margin: 1px
  padding 2px 5px 2px 5px
  color: black
  text-align: center
  font-size: 1em
  font-style: bold
  border-radius: 3px
  width: 100%
  line-height: 16px
  border: 1px solid #d0d0d0

  &.red
    background-color: #ff6666

  &.yellow
    background-color: #ffdf80

  &.green
    background-color: #c4ffd2