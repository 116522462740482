.customerIDCheck
  .info
    margin: 16px 0
    font-style: italic

  .hyperlink
    margin: 16px 0

  .camera-button-wrapper
    display: flex
    text-align: center
    .capture-id-btn
      display: flex
      padding-left: 0
      svg
        height: 2.8rem
        width: 2.5rem
    & > *
      flex-grow: 1
    label:first-child
      margin-right: 10px

  .disabled
    opacity: 0.5

  .customerId-helper
    text-align: left

  input
    opacity: 0
    display: none

  .customerId-wrapper
    position: relative
    margin: 0 auto
    margin-top: 16px
    max-width: 90%
    .customerId-preview
      width: 100%
      vertical-align: middle
    button
      position: absolute
      width: auto
      height: auto
      padding: 0
      svg
        height: 2.3rem
        width: 2.5rem
      &.btn-delete-img
        bottom: 10px
        right: 10px
      &.btn-expand-img
        top: 10px
        right: 10px
      &.btn-rotate-img
        top: 10px
        left: 10px

  .customerId-instruction
    width: 300px
    position: relative
    .description
      display: flex
      margin-bottom: 10px
      span
        line-height: 25px
        font-size: 1rem
        margin-left: 20px

    .customerId-mock-phone
      position: relative
      height: 185px
      border: 8px solid
      border-radius: 16px
      color: var(--online-mid-grey)
      margin-bottom: 30px

      img
        position: absolute
        margin: auto
        top: 0
        bottom: 0

        &.correct
          height: 120px
          left: 20px

        &.incorrect_orientation
          transform: rotate(270deg)
          height: 60px
          right: 0
          left: 0

        &.incorrect_size
          object-fit: none
          height: 122px
          left: 20px
        &.blurry
          height: 120px
          left: 20px
          filter: blur(2px)

      .circle
        height: 15px
        width: 15px
        border: 12px solid
        border-radius: 15px
        position: absolute
        top: 71px
        right: 20px

    .icon
      height: 25px
      width: 25px
      &--approved
        color: var(--greenalert-state)
      &--alert
        color: var(--redalert-state-hover)

  .modal
    .customerId-expanded
      height: 100%
      object-fit: cover
      margin: 0 auto
      height: 100%
      max-height: 100vh

    .submit
      margin-top: 14px

@media all and (min-width: 1024px)
  .customerIDCheck
    .camera-button-wrapper  
      .capture-id-btn
        display: none
