.content-left.customer-info
  h1 
    margin-bottom: 16px
  form
    width: 50%

  .list
    width: 50%
    border-bottom: 0

    .item
      flex-basis: 50%

    +p
      margin-top: 0
.company-form
  width: 40%

.companies-dropdown
  width: 60%
  margin-left: 16px;

.b2b__checkbox__wrapper
  display: flex
  flex-direction: column
  .checkbox
    display: inline-flex
    align-items: center
    label
      margin: 0px 8px 16px
    p
      margin: 0px 0px 16px
  


/* ============================ Media Queries ============================ */
@media all and (max-width: 1366px)
  .content-left.customer-info
    form
      width: 50%

    .list
      width: 50%

@media all and (max-width: 1024px)
  .content-left.customer-info
    form
      width: 50%

    .list
      width: 50%

      .item
        flex-basis: 100%

@media all and (max-width: 768px)
  .content-left.customer-info
    form
      width: 100%

    .list
      width: 100%