.creditStep
  display: grid
  grid-template-columns: var(--item-status-circle-dimension) max-content
  grid-gap: 10px 24px
  grid-template-areas: 'indicator labels aside'\
                       'indicator extraContent extraContent'
  margin-bottom: 24px

  &.disabled
    pointer-events: none

  &__indicator
    grid-area: indicator
    width: var(--item-status-circle-dimension)
    height: var(--item-status-circle-dimension)
    border-radius: var(--item-status-circle-dimension)
    background: var(--background-extra-medium)
    color: var(--color-txt-extra-light)
    font-size: 0.85rem
    line-height: var(--item-status-circle-dimension)
    text-align: center

    &.approved
      background-color: var(--greenalert-state)
      border: 2px solid

    &.pre_approved
      background-color: var(--greenalert-state)
      border: 2px solid

    &.partial_approved
      background-color: var(--yellowalert-state)
      border: 2px solid

    &.withdrawn
      background-color: var(--redalert-state)
      border: 2px solid

    &.refused
      background-color: var(--redalert-state)
      border: 2px solid

    &.pending
      background: transparent

  &__labels
    grid-area: labels
    line-height: 1.6rem

  &__aside
    grid-area: aside

  &__extraContent
    grid-area: extraContent
