:root {
  @font-face {
    font-family: 'Default-Body'
    font-display: swap
    src: url('assets/fonts/Arimo-Regular.otf') format("opentype")
  }

  @font-face {
    font-family: 'Default-Title'
    font-display: swap
    src: url('assets/fonts/Arimo-Bold.otf') format("opentype")
  }

    @font-face {
    font-family: 'online-regular'
    font-display: swap
    src: url('assets/fonts/Arimo-Regular.otf') format("opentype")
  }

  @font-face {
    font-family: 'online-semibold'
    font-display: swap
    src: url('assets/fonts/Arimo-Bold.otf') format("opentype")
  }

  @font-face {
    font-family: 'online-bold'
    font-display: swap
    src: url('assets/fonts/Arimo-Bold.otf') format("opentype")
  }

  --background-extra-dark: #949494
  --background-extra-light: #ffffff
  --background-extra-medium: #e9e9e8
  --border-color-active: #08BED5
  --border-color-extra: #949494
  --border-color: #e9e9e8
  --border-radius: 3px
  --border-radius-large: 6px
  --border-radius-small: 3px
  --border-radius-extrasmall: 2px
  --border-width: 2px
  --button-default: #08BED5
  --button-highlight: #0b8b9c
  --button-spacing: 24px
  --button-text-default: #ffffff
  --button-text-highlight: #ffffff
  --checkbox-border-color: #D2D2D2
  --color-extra-dark: #343434
  --color-extra-light: #e9e9e9
  --color-extra-medium: #949494
  --color-header-bottom: #000000
  --color-header-top: #003031
  --color-promotions: #648014
  --color-redalert: #ffffff
  --color-txt-dark: #343434
  --color-txt-extra-light: #ffffff
  --color-txt-highlight: #0b8b9c
  --color-txt-light: #949494
  --color-white: #ffffff
  --greenalert-state: #4AB312
  --greenalert-transparent-state: rgba(147, 191, 24, 0.25)
  --inactive-state: #B3B3B3
  --info-icon-white: #ffffff
  --info-icon: #0b8b9c
  --input-background: #ffffff
  --item-status-circle-dimension: 24px
  --layout-footer-height: 80px
  --layout-max-width: 1366px
  --login-gradient: #005758
  --online-approved: #4AB312
  --online-background-color: #ffffff
  --online-black: #000000
  --online-border-color-focus: #08BED5
  --online-border-color: #E8E8E8
  --online-border-radius: 4px
  --online-box-shadow-color: rgba(8, 188, 212, 0.5)
  --online-box-shadow: 0 0 4px 0
  --online-dark-blue: #0b8b9c
  --online-dark-grey: #777777
  --online-dropdown-background: #ffffff
  --online-dropdown-hover: #E8E8E8
  --online-dropdown-item-background: #ffffff
  --online-dropdown-item-selected-background: #F8F8F8
  --online-grey: #F2F2F2
  --online-layout-column-gap: 16px
  --online-layout-footer-height: 104px
  --online-layout-header-background-prepend: #ffffff
  --online-layout-header-color-prepend: #343434
  --online-layout-header-height-prepend-default: 44px
  --online-layout-header-height-prepend: 44px
  --online-layout-header-height: 106px
  --online-layout-large-button-height: 48px
  --online-layout-medium-button-height: 40px
  --online-layout-button-font-size: 16px
  --online-layout-max-width: 952px
  --online-layout-padding: 16px
  --online-light-blue: #08BED5
  --online-light-grey: #FAFAFA
  --online-lighter-blue: #08BED5
  --online-mid-grey: #CFCFCF
  --online-refused: #ff0000
  --online-tutorial-background-text-color: #ffffff
  --online-tutorial-background-gradient: #005758
  --online-typography-bold: 'online-bold', Arial, Helvetica, sans-serif
  --online-typography-regular: 'online-regular', Arial, Helvetica, sans-serif
  --online-typography-semibold: 'online-semibold', Arial, Helvetica, sans-serif
  --redalert-state-hover: #d10000
  --redalert-state: #ff0000
  --redalert-transparent-state:rgba(255, 0, 0, 0.3)
  --slideshow-btn-size: 76px
  --slideshow-thumb-size: 50px
  --table-color-header: #000000
  --transition-fast: .05s ease-in-out
  --transition-slow: .25s ease-in-out
  --typography-body: Arial, Helvetica, sans-serif
  --typography-bold: 700
  --typography-line-height: 1.6rem
  --typography-normal: 400
  --typography-size: 12px
  --typography-title: Arial, Helvetica, sans-serif
  --user-menu-icon-color: #ffffff
  --yellowalert-state-txt: #000
  --yellowalert-state: #CCD814
  --yellowalert-transparent-state: rgba(203, 216, 20, 0.5)
  --neutralalert-transparent-state:rgba(43, 164, 217, 0.4)
  --notification-area: #93BF185C
}