.cart__discount__form
    display: flex
    flex-wrap: wrap
    margin-bottom: 16px

    > div
        flex-basis: 50%
        box-sizing: border-box

        input
            width: 100%

        p
            margin-top: 0

    .max-value
        padding-top: 2px
        padding-left: 16px

        label
            left:24px

        & .active
            left: 8px
    
    > button
        width: calc(100% / 4)
        margin-left: auto