.receipt
  display: flex
  flex-wrap: wrap
  width: 100%

  .quote-info
    flex-direction: column

  .header
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    justify-content: space-between
    width: 100%
    position: relative

    .details-wrapper
      position: absolute
      top: -12px
      right: 0 

    .description-list
      width: auto
      padding-right: 0

      > div
        flex: auto
        padding-right: 0
        border: 0

        &:after
          content: "|"
          margin-left: 8px

        .label
          font-weight: var(--typography-bold)

          &:after
            content: ":"

      > div:last-child
        flex: auto
        padding-right: 0
        border: 0

        &:after
          content: ""
          margin-left: 0

  .btn
    &-add
      height: 32px
      margin-right: 10px
      padding-left: 0
      white-space: nowrap

      img
        height: 17px
        float: left
        padding-left: 5px

    &-print
      height: 32px
      padding: 0
      white-space: nowrap
      margin-bottom: 10px

      img
        height: 32px

    &-cancel
      float: right
      height: 32px
      margin-left: 16px
      white-space: nowrap

  .dates
  &.description-list

    dt
      margin-right: 4px

    dd
      margin-right: 16px

      &:last-child
        margin: 0

  .body
    width: 100%
    margin-bottom: 24px

    .btn-print
      float: right

    .customer-list, .store-list
      width: 50%
      margin: 16px 0

      dd, dt
        border-bottom: 2px solid var(--border-color)

    .customer-list
      float: left
      border-right: 1px solid rgba(0,0,0, 0)

    .store-list
      float: right

  &__cancel-modal-body,
  &__cancel-modal-actions
    width: 100%
    padding-right: 24px

  &__cancel-modal-actions
    display: flex
    flex-direction: row
    justify-content: space-between
    padding-top: 16px

    .checkbox
      width: calc(100% - 138px)

/* ============================ Media Queries ============================ */

@media all and (max-width: 1550px)

  .quote-receipt
    .dates
      width: 33.33%

@media all and (max-width: 1128px)

  .quote-receipt
    .body
      .total
        width: 80%

@media all and (max-width: 1024px)

  .quote-receipt
    .dates
      width: 35%

@media all and (max-width: 768px)

  .quote-receipt
    .dates
      width: 50%