.recycle-report

  &__filter-form-wrapper.section
    width: calc(33.3333% - 8px)
    margin-right: 8px

  &__summary-table-wrapper.section
    width: calc(66.6666% - 8px)
    margin-left: 8px

  &__summary-table-wrapper--disabled > .recycle-report__summary-table,
  &__details-table-wrapper--disabled > .recycle-report__devices-table
    opacity: 0.5

  &__title
      width: 100%
      height: auto
      margin-bottom: 24px

  &__devices-table 
    .scrolling-table__scroller
      margin-left: 33%

  &__filter-form,
  &__summary-table,
  &__devices-table
  
  &__filter-form
    width: 100%

    .tabbedContent
      margin-bottom: 0

      &--content
        padding-top: 16px
        border-bottom: none

      &--tab
        padding: 8px 0 8px 8px
      &--tab__active
        border-bottom: 3px solid var(--button-default)
        
    
    form
      justify-content: space-between
      flex-wrap: wrap
      flex-direction: row

      .input__wrapper
        height: 48px

      > div
        width: 100%

      label
        z-index: 0
      
      label.active
        opacity: 0.75

      .react-datepicker-recycle-reports
        width: 49%
        flex-basis: auto

        .react-datepicker__input-container
          width: 100%

          input[type='text']
            width: 100%
            
    .button
      margin: 16px 0 0 auto
  
  &__summary-table
    width: 100%

    .recycle-report__export-button
      float: right
      margin-top: 24px

  &__devices-table
    width: 100%

    .center.no-data
      text-align: left
      padding-left: 20%
        
    .table
      td
        white-space: nowrap

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)
  .recycle-report
    &__summary-table
      .table
        th
          white-space: initial

    &__devices-table
      .center.no-data
        padding-left: 15%
  

@media all and (max-width: 768px)
  .recycle-report
    &__filter-form
      width: 100%
      padding-right: 0

      .datepicker label.active
        left: -10%

    &__summary-table
      width: 100%
      margin-top: 16px

      .table
        th
          max-width: 125px
          white-space: initial

    &__devices-table
      .center.no-data
        padding-left: 8%
