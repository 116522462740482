
.input__wrapper
  height: auto
  display: flex
  flex-direction: column
  flex-basis: 100%

  textarea
    overflow: auto
    outline: none
    box-shadow: none
    resize: none
    border: 2px solid var(--border-color)
    border-radius: var(--border-radius)

  .input__container
    width: 100%
    position: relative
    flex: 1
    border-radius: var(--online-border-radius)
    border: 2px solid var(--online-border-color)

    .input__input
      padding-left: 8px
      width: 100%
      height 100%
      border: none
      color: var(--color-txt-dark)
      background: transparent
      display: block

      &:focus
        outline: 0
        box-shadow: 0

      &--with-icon
        width: calc(100% - 40px)

    .input__input[type=number]::-webkit-inner-spin-button,
    .input__input[type=number]::-webkit-outer-spin-button
      -webkit-appearance: none
      margin: 0
    .input__input[type=number]
      -moz-appearance:textfield

    .input__floating-label
      width: auto
      padding: 0
      position: absolute
      z-index: auto
      left: 16px
      top: 9px
      user-select: none
      transition: font-size .25s, top .25s, opacity .25s ease-in-out
      transform-origin: 0 0

      &--active
        top: 12px
        z-index: 0
        opacity: 0.75
        font-size: 0.8em

      &--active + .input__input
        padding-top: 14px

    .input__floating-label
      color: var(--online-dark-grey)

    .input__input-img, svg
      position: absolute
      right: 8px
      width: 24px
      height: 24px
      cursor: pointer

    &--focused
      border: 2px solid var(--online-border-color-focus)

    // variant rules

    &--no-radius
      border-radius: 0

      .input__input
        border-radius: 0

    &--underline
      border-radius: 0
      border: 0
      border-bottom: 2px solid var(--online-border-color)

      .input__floating-label
        left: 16px
        top: 10px

      .input__input
        border: 0

    &--underline^[1..-1]--focused
      .input__input
        border: 0

    &--underline^[1..-1]--large-height
      .input__floating-label
        top: 18px

      .input__floating-label
        &--active
          top: 1px
          left: 16px
          font-size: 0.8em

    &--underline^[1..-1]--focused
      border-bottom: 2px solid var(--online-border-color-focus)

    &--underline^[1..-1]--error
      border: 0
      border-bottom: 2px solid var(--redalert-state)

    &--rounded
      border: 0
      border: 2px solid var(--online-border-color)
      border-radius: 40px

      .input__input
        position: absolute
        bottom: 0
        padding: 0px 16px

      .input__floating-label
        left: 10px
        top: 11px

        &--active
          top: 2px
          font-size: 0.8em

      .input__input-img
        top: 23px

    &--rounded^[1..-1]--large-height
      .input__floating-label
        left: 16px
        top: 19px

        &--active
          top: 2px
          font-size: 0.8em

    &--rounded^[1..-1]--focused
      border: 2px solid var(--online-border-color-focus)

    &--disabled
      background-color: var(--online-light-grey)

    &--error
      border: 2px solid var(--redalert-state)

      .input__input
        border: 0

    // size rules

    &--medium-height
      height: 40px

      .input__input
        padding: 15px 24px 0 6px

        &--outside-label
          padding: 0 24px 0 8px

      .input__floating-label
        left: 8px

        &--active
          top: -2px
          left: 0
          padding-left: 6px

        &--outside
          left: 0
          top: -24px
          padding: 0

      .input__input-img
        top: 7px

    &--medium-height.input__container--rounded
      .input__input
        padding-left: 8px

    &--large-height
      height: 56px

      .input__floating-label
        top: 17px

        &--active
          top: 3px
          font-size: 0.8em

      .input__input
        padding: 20px 16px

      .input__input-img, svg
        top: 14px

div.input__wrapper--half-width
  width: 50%

div.input__wrapper--third-width
  width: 33%