.hyperlink
  display: inline
  text-decoration: none
  color: var(--color-txt-dark)
  line-height: 24px

  &.plus::before
    position: relative
    top: 2px
    display: inline-block
    content: "+"
    width: 24px
    height: 24px
    font-size: 1.4rem
    font-weight: var(--typography-bold)
    text-align: center
    line-height: 24px
    border-radius: var(--border-radius)
    margin-right: 8px
    color: var(--color-txt-extra-light)
    background: var(--button-default)
    transition: var(--transition-slow)

  &.plus:hover::before
    background-color: var(--button-highlight)

  &.underline
    text-decoration: underline

  &:hover
    text-decoration: none
    cursor: pointer