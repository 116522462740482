.counter-row
  width: 100%
  margin-bottom: 10px
  margin-top: 10px
  white-space: nowrap

.counter
  vertical-align: top
  text-align: center
  color: #444444
  display: inline-block
  min-height: 30px
  letter-spacing: 0.01em
  margin: 3px
  padding: 5px
  border-radius: 5px
  box-shadow: 1px 2px 2px #d0d0d0
  width: 70px

  &.red
    background-color: #ff6666
    margin-right: 3px

  &.yellow
    background-color: #ffdf80
    margin-right: 3px

  &.green
    background-color: #c4ffd2
    margin-right: 3px

  &.loading
    background-color: #f0f0f0
    opacity: 0.5

  .total
    color: #444444
    font-size: 1.8em
    font-weight: 400
    height: 90%

  .label
    color: #444444
    font-size: 0.85em
    font-weight: 400
    height: 90%
    overflow: hidden
    position: relative
    text-decoration: none
    vertical-align: top

.counter-group
  display: inline-block
  padding-right: 5px
  white-space: nowrap
  text-align: center

  .counter-group-title
    text-align: center
    margin-bottom: 10px
    letter-spacing: 1px
    font-size: 1em