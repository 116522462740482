.cart-item
  height: 6rem

  > div
    display: flex
    align-items: center
    font-size: 1.2rem

  .label
    img
      height: 80px
      padding-right: 24px
      vertical-align: middle

    span
      white-space: normal

  .value
    width: 33%
    display: flex
    justify-content: flex-end
    font-family: var(--typography-title)
    align-items: center

    span
      width: 25%
      text-align: right

    .price
      position: relative

  .action-content
  .action-content:hover
  .action-content:focus
    margin-left: 24px

  &:last-child
    border-bottom-width: 2px

  &.small
    height: auto
    padding-right: 8px

    > div
      font-size: 1rem

    &:last-child
      border-bottom-width: 2px

    .label
      img
        height: 40px
        padding-right: 8px

    .value
      width: 50%

.manual-cto-img
  display: inline-block
  margin-right: 42px
  margin-left: 18px
  padding: 6px
  border: 2px solid var(--color-extra-dark)
  border-radius: 3px 6px
  font-family: var(--typography-title)
  
.cart-item-quantity
  width: 48px
.cart-item-quantity-true
  border: 2px solid var(--border-color)
.cart-item-quantity-false
  border: 0

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .cart-item
    .value
      width: 30%