.checkbox
  position: relative

  &__input
    display: none

  &__square
    display: block
    float: left
    margin-right: 8px
    background-image: url('assets/images/checked.svg')
    background-color: white
    height: 24px
    width: 24px
    color: transparent
    cursor: pointer
    line-height: 15px
    background-position: 0 24px
    background-repeat: no-repeat
    background-size: 20px 20px
    border: 2px solid var(--checkbox-border-color)
    border-radius: var(--border-radius-small)

  &--disabled
    opacity: 0.5

    .checkbox__square
      cursor: default

  .checkbox__input:checked + .checkbox__square
    background-position: 0 1px
