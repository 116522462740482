.something-wrong-wrapper
  display: flex
  align-items: center
  width: var(--layout-max-width)
  margin: 0 auto
  justify-content: flex-start

  & > img
    margin: 10%

  h1
    font-size: 4rem
    line-height: 6rem

  h2
    font-size: 3rem
    line-height: 2.2rem

  p
    font-size: 1.5rem
