.criteria-list
  list-style: none

  li
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    margin: 8px 0 0
    padding: 8px 0 16px 0
    border-bottom: 1px solid var(--border-color)
    
    &.criteria-item--with-help
      flex-direction: column

    &:last-child
      border-bottom: none

    > span
      display: flex
      flex-direction: row
      align-items: center

      .criteria-question__gallery-btn
        margin: 0 12px
        button
          width: 24px
          height: 24px
          color: var(--border-color)
          font-weight: var(--typography-bold)
          border-radius: 50%
          border: 2px solid var(--border-color)
        
        button:hover
          color: var(--border-color)
          opacity: 1
          border-color: var(--border-color)

      &.criteria-question__answer-btns
        margin-left: 24px
        button
          display: block
          width: 64px
          text-align: center
          border: 1px solid var(--border-color)
          color: var(--color-txt-dark)
          font-weight: var(--typography-bold)
          &:hover
            color: var(--color-txt-dark)
            opacity: 1
          &:first-child
            margin-right: 8px
          &.selected
            color: var(--button-text-default)
            background-color: var(--button-default)
            opacity: 1

    button
      display: flex
      justify-content: center
      align-content: center
      padding: 0
      background-color: transparent

      img
        display: block
        width: 100%
        height: auto

      &:not(:disabled):hover,
      &:not(:disabled):focus
        opacity: .5
        background-color: transparent

      &:disabled
        background-color: transparent
        opacity: .25
        cursor: default

    .criteria--help
      font-size: .85rem
      padding: 8px 8px 8px 30px

      ul>li
        margin: 0
        padding: 4px 0
        border: none

  .additions
    span > span:last-child
      width: 32px

  .modal
    .modal-content
      overflow: auto
      overflow-x: none

  .criteria--help-text
    margin-top: 2em
  
    div
      margin-bottom: .5em