.radio-buttons
  position: relative
  display: inline-flex
  list-style-type: none

  li
    display: flex
    align-items: center
    position: relative
    width: auto
    min-height: 48px
    margin: 0 8px 16px 0
    padding: 0 8px
    cursor: pointer
    border: 2px solid var(--background-extra-light)
    border-radius: var(--border-radius)

    .labelContainer
      display: flex

    &.selected
      .checkmark:after
        background-color: var(--color-extra-dark)

    &:hover ~ .checkmark:after
      background-color: var(--color-extra-dark)

    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

      &:checked ~ .checkmark:after
        background-color: var(--color-extra-dark)

    .checkmark
      height: 24px
      width: 24px
      border-radius: 24px
      background-color: var(--input-background)
      border: 2px solid var(--background-extra-medium)

      &:after
        content: ""
        width: 16px
        height: 16px
        display: block
        border-radius: 24px
        margin-top: 2px
        margin-left: 2px
        background-color: var(--input-background)
        transition: var(--transition-slow)

    label
      position: initial
      margin-left: 8px

    &.selected,
    &:hover
      border: 2px solid var(--border-color)
      border-radius: var(--border-radius)

    &.disabled
      cursor: not-allowed

.radio-buttons.horizontal
  flex-direction: row

.radio-buttons.vertical
  flex-direction: column

.radio-buttons.borderless
  li
    border-color: transparent
.radio-buttons.no-margin
  li
    margin: 0

.radio-buttons.wrap
  display: grid
  grid-template-columns: 1fr 1fr 1fr

.radio-buttons.big
  li
    .checkmark
      height: 30px
      width: 30px
      border-radius: 30px

      &:after
        width: 24px
        height: 24px
        border-radius: 24px
        margin-top: 2px
        margin-left: 2px
