.dashboardHeader
  display: flex
  justify-content: space-between

  .logo
    width: 250px
    height: auto
    object-fit: contain

  .socket-status
    align-self: center
    margin-right: 10px