.wlIcon
  float: left
  margin: 0px 2px
  padding 2px 1px
  color: white
  text-align: center
  font-size: 0.8em
  border-radius: 3px
  width: 22px
  line-height: 14px
  box-shadow: 1px 2px 2px #d0d0d0
  cursor: pointer