.datepicker-container
  margin-left: 10px
  max-width: 400px
  display: flex
  align-items: center
  margin-bottom: 24px
  & > div:first-child
    margin: 0 16px

.datepicker
    flex: 1
  .react-datepicker-wrapper
    width: 100%
    .react-datepicker__input-container
      width: 100%
    input[type='text']
      width: 100%
      background: var(--input-background) url('assets/images/ico-calendar.svg')  97% 50% no-repeat
      background-size: 20px
  .react-datepicker-popper
    z-index: 2    

.datepicker.full-border
  .react-datepicker-wrapper
    input[type='text']
      height: 100%
      padding: 18px 6px 4px 
      border: 2px solid var(--border-color)
      border-radius: var(--online-border-radius)

  label
    z-index: 0
    opacity: 0.75
    font-size: 1em
  label.active
    top: 0
    font-size: 1.1em
    padding-left: 12px

.datepicker.disabled
  opacity: 0.7
  cursor: default
  pointer-events: none

.settlements-summary
  width: 100%
  clear: both

  h2
    display: inline-block
    background: #fff
    padding: 0 40px 0 10px
    z-index: 1

  hr
    width: 100%
    height: 1px
    border: 0
    background-color: var(--border-color)
    margin-top: -9px
    z-index: 0

  .settlements-stats
    display: flex
    flex-direction: row
    flex-wrap: wrap
    float: left
    width: 70%
    list-style: none
    margin: 24px 0

    li
      display: inline-flex
      flex-wrap: wrap
      flex-direction: column
      width: 20%
      border-right: 1px solid var(--border-color)
      padding: 1rem

      h3
        margin: 0 0 .5rem

  .export-settlements
    float: right
    margin: 40px 0 0 8px

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .quote-receipt
    .dates
      width: 35%

@media all and (max-width: 768px)

  .settlements-summary
    .settlements-stats
      width: 100%

    .export-settlements
      float: right
      margin: 0 0 24px 8px