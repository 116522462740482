.outcomes
  width: 100%
  display: flex

  h1
    padding-bottom: 16px

  .list
    border: none

    li
      align-items: center

  .recycleDisclaimer
    line-height: 16px
    line-height: 24px
    display: inline-flex

  .quote-review
    ul
      border: 0
      
  .gift-card-container
    display: flex
    justify-content: center
    align-items: center
    width: 30vb;
    height: 20vb;
    background: radial-gradient(circle at bottom left,transparent 25%,#f1f1f1 25.5%, #f1f1f1 36%, transparent 37%, transparent 100%),radial-gradient(circle at top right,transparent 34%,#f1f1f1 34.5%, #f1f1f1 45.5%, transparent 46%, transparent 100%)
    background-size: 2em 2em
    background-color: var(--background-extra-medium)
    opacity: 1
    border-radius: var(--border-radius)

    .gift-card-price
      font-size: 2rem
      font-family: var(--typography-title)
      color: var(--color-txt-dark)

  .checkbox
    p
      line-height: 24px

  .trade-steps__id-photo
    max-width: 300px

  aside

    .trade-summary

      .summary-product
        display: flex
        flex-direction: row

        .product-img
          width: 80px
          height: 80px

          img 
            width: auto
            height: 100%

        .item-details
          flex-grow: 1

          > div > span:first-child
            font-family: var(--typography-title)

          > div:first-child > span:first-child
            font-size: 1.2rem

          button
            display: none

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .gift-card-container
    width: 66.66%

@media all and (max-width: 768px)

  .gift-card-container
    width: 100%
