.cart__container
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    padding: 16px
    background: var(--background-extra-medium)
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius-large)

    h3 
        padding: 0
        margin: 0
        font-family: var(--typography-title)

    > div 
        border-bottom: 1px solid var(--border-color)
        padding: 0 0 8px

        h4 
            margin: 0

    > div:last-child
        margin: 16px 0 0
        border:none
    
        h4, span
            margin: 0
            padding: 0
            color: var(--color-txt-dark)

    .cart__container__options
        padding: 16px
        background: var(--background-extra-light)
        border: 1px solid var(--border-color)
        border-radius: var(--border-radius-large)
        position: relative
        margin: 0

        .section-content > div 
                margin-top: 16px
                
        .section-icon-wrapper
                position: absolute
                top: 4px
                right: 4px

        h4 
            text-align: left
            font-size: 1rem
            letter-spacing: 0px
            margin: 0
            padding: 0
        span
            text-align: left
            font-size: 1rem 
            font-family: var(--typography-title)
            font-weight: var(--typography-bold)
            letter-spacing: 0px

        ul 
            list-style: none
            display: grid;
            grid-template-columns: 1fr 1fr
            grid-auto-flow: row
            grid-gap: 8px

            li 
                background: var(--background-extra-light) 
                border: 2px solid var(--border-color)
                border-radius: var(--border-radius)
                &:hover
                    border: 2px solid var(--border-color-active)
                    text-decoration: none
                    cursor: pointer

                &.selected
                    border: 2px solid var(--border-color-active)

                &.disabled
                    pointer-events: none
                    cursor: initial
                    opacity: 0.5

                > div
                    display: flex
                    flex-direction: column
                    justify-content: center
                    flex: 1
                    padding: 8px
                    

                    p 
                        text-align: left
                        font-size: 1rem
                        font-weight: bold
                        margin: 0
                        padding: 0
                    
                    span 
                        text-align: left;
                        font-size: 0.71rem
                        font-weight: normal
    

    .cart__container__sections
        display: flex
        flex-direction: column
        gap: 8px

        h4 
            text-align: left
            font-size: 1rem
            margin: 0
            padding: 0
        
        .cart__container__sections__items
            padding: 16px
            margin: 0
            background: var(--background-extra-light)
            border: 1px solid var(--border-color)
            border-radius: var(--border-radius-large)
            position: relative

            .section-content > div 
                margin-top: 16px

            .section-icon-wrapper
                position: absolute
                top: 4px
                right: 4px

            ul 
                list-style : none

                li
                    display: flex
                    flex-direction: row
                    flex-wrap: wrap
                    padding-bottom: 16px
                    border-bottom: 1px solid var(--border-color)

                li:last-child 
                    margin-bottom: 0
                    padding-bottom: 0
                    border-bottom: none
    
    .cart__container__summary
        display: grid
        grid-template-columns: 1fr 1fr
        grid-auto-flow: row
        width: 100%

        span
            font-size: 1rem

        .cart__container__summary__large 
            text-align: left
            font-size: 1.71rem
            font-family: var(--typography-title)
            font-weight: var(--typography-bold)

        .cart__container__summary__medium
            text-align: right
            font-size: 1rem
            font-weight: bold
        
        > div:first-child
            display: flex
            flex-direction: column
            flex: 1
            
            > div:first-child
                margin-bottom: 12px
             .cart__container__summary__details__link
                text-decoration: underline
                font-size: 1rem
                background: none
                border: none;
                padding: 0
                cursor: pointer;
                font-family: var(--typography-body)
                        
        > div:last-child
            display: flex
            flex-direction: column
            align-items: flex-end

            div 
                display: flex
                flex-direction: column
                align-items: flex-end

        .cart__container__summary__details
            display: flex
            flex-direction: column
            align-items: flex-start
            gap: 8px

            .payment-details
                width: 100%
                margin-top: 2rem

.cart__container__summary__swap__link
    padding: 8px 0 0
    >button
        margin-left: 8px
        text-decoration: underline
        font-size: 1rem
        background: none
        border: none;
        padding: 0
        cursor: pointer;
        font-family: var(--typography-body)