.howToFindIMEI
  &.disabled
    pointer-events: none
  &__link
    display: flex
    cursor: pointer
    margin-top: 8px
    margin-bottom: 16px
    line-height: 16px
    font-size: .95rem
    color: var(--button-default)
    text-decoration: underline
    align-items: center

    .icon__info-icon
      width: 22px
      height: 22px
      margin-right: 8px
      border-radius: 50%
      fill: var(--info-icon)
      border: 2px solid var(--info-icon)

    &:hover
      text-decoration: none

  &__modal
    width: 100%
    h2
      margin-bottom: 8px
    .description
      margin-bottom: 25px

    p
      font-size: 16px

    .instructions
      border: 1px solid #CBCBCB
      border-radius: 4px
      width: 100%
      margin-bottom: 20px
      
      &__title
        height 38px
        line-height: 38px
        text-align: center
        background: #F2F2F2
      &__body
        padding: 16px 8px 8px 8px

      img
        height: 124px
        margin: 0 auto
        width: 160px
        display: block
 
