.templateEditor
  display: grid
  min-height: 100%
  grid-template-columns: 50%
  grid-auto-rows: max-content
  grid-gap: 32px
  grid-template-areas: 'title notifications'\
                       'editor preview'

  .templateTitle
    grid-area: title

  .templateIndicator
    grid-area: notifications
    background: var(--notification-area)
    width: max-content
    justify-self: end
    height: 32px
    line-height: 32px
    padding: 0 8px

    .templateIndicatorIcon
      vertical-align: sub
      margin-right: 8px

  .templateEditionArea
    grid-area: editor
    min-height: 700px

    .templateDocs
      position: absolute
      top: 160px
      left: 24px
      right: 30px
      height: 588px
      background: #fff
      overflow: auto
      z-index: 10
      border: 2px solid var(--online-grey)

      .heading
        margin-left: 16px

      ul
        list-style: none

        li
          display: flex
          height: 36px
          line-height: 36px
          padding:  0 16px
          justify-content: space-between

          .icon
            width: 30px
            height: 30px
            margin-top: 5px

        li:hover
          cursor: pointer
          background: var(--online-grey)


    .templateForm
      display: flex
      flex-direction: column

      .blockToggles
        display: flex
        justify-content: flex-end
        width: 100%
        border: 2px solid var(--online-grey)
        border-bottom: 0
        border-top-left-radius: var(--border-radius)
        border-top-right-radius: var(--border-radius)
        padding: 8px

        .button
          margin: 0 4px

      &--textArea
        textarea
          display: block
          width: 100%
          min-height: 596px
          padding: 5px
          overflow: auto
          outline: none
          box-shadow: none
          resize: none
          border: 2px solid var(--online-grey)
          border-top: 0
          border-bottom-left-radius: var(--border-radius)
          border-bottom-right-radius: var(--border-radius)


      &--actionButtons
        margin-top: 16px
        display: grid
        grid-template-columns: 1fr 1fr
        grid-row-gap: 16px
        grid-column-gap: 32px

    .statusMessage
      background: #00000008
      width: 100%
      margin-top: 16px
      height: 32px
      line-height: 32px
      padding: 0 8px

      .statusMessageIcon--approved
        color: #93BF18
        vertical-align: sub
        margin-right: 8px

      .statusMessageIcon--alert
        color: #EBC60FF2
        vertical-align: sub
        margin-right: 8px

  .templatePreviewArea
    grid-area: preview

    .tabbedContent--content
      padding: 8px

    .templatePreview
      width: 100%

      .error
        color: var(--redalert-state)

      .subjectPreviewArea
        border: 0
        width: 100%
        height: 40px
        font-weight: bold
        font-size: 16px
        margin-bottom: 16px

      .bodyPreviewArea
        border: 0
        width: 100%
        height: 596px
        overflow: auto

      .sendEmailForm, .sendSMSForm
        display: grid
        grid-template-columns: 1fr 1fr
        grid-column-gap: 16px
        grid-row-gap: 0px
        margin-top: 32px

        .form-button-wrapper
          grid-column: 1 / 3
          display: grid
          grid-template-columns: 1fr 1fr
          grid-column-gap: 16px

          .button
            margin: 0
