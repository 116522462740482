.content-left
  width: 60%
  margin-left: -1px
  padding: 0 24px 0 0

  h2.options-list-title
    margin-bottom: 16px

  .options-list-title.keyboard + .dropdown
    margin-bottom: 20px
    width: 40%

.content-right
  width: 40%
  min-height: calc(100vh - 280px)
  margin-left: -1px
  padding: 0 0 24px 24px
  border-left: 1px solid var(--border-color)

.product-options-page-title
  margin-bottom: 2rem

.product-option-selected
  display: flex
  gap: 16px
  margin-bottom: 1rem
  padding-bottom: 1rem
  border-bottom: 1px solid var(--border-color)


.product-option-selected-slideshow
  width: 60%
  .slideshow
      width: 100%
  & .product-option-selected-single-image
    width: 100%
    height: auto

.product-option-selected-details
  width: 40%

  div 
    margin-bottom: 16px

  h4
    margin: 0 0 0

  & div:first-child h4
    margin: 0 0 16px 0
    
  ul
    list-style: none
    margin-bottom: 16px
    text-align: left
    letter-spacing: 0px
    
    li a
      text-align: left
      text-decoration: underline
      letter-spacing: 0px
      color: var(--button-default)



.slideshow__slider
  min-width: 100% !important

/* ============================ Media Queries ============================ */

@media all and (max-width: 1024px)

  .content-left
    width: 60%

  .content-right
    width: 40%

@media all and (max-width: 768px)

  .content-left
    width: 40%

  .content-right
    width: 60%

@media all and (min-width: 768px)
  .slideshow__slider
        min-width: 100% !important

@media all and (max-width: 768px)

  .product-option-selected
    flex-wrap: wrap

  .product-option-selected-slideshow
    width: 100%

  .product-option-selected-details
    width: 100%