.returnContract
  .modal-content
    margin-bottom: 0
    overflow-y: initial

    .return-form--select
      width: 50%
    
    .radio-buttons li.selected
      border-color: var(--background-extra-light)
    .radio-buttons
      margin-top: 24px

      .labelContainer
        flex-direction: column

        .italic
          font-style: italic

  .modal-buttons
    margin-bottom: 0