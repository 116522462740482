.badges
  display: inline-block

  & > .badge
    display: inline-flex
    padding: 6px
    font-size: 0.9rem
    background: var(--badge-bg, #343434) 
    color: #fff
    border: 1px solid var(--badge-border, #343434)
    border-radius: 0
    margin-right: 1px
    align-items: center
    line-height: normal

    &:first-child
      border-top-left-radius: 2px;
      border-bottom-left-radius: 6px;

    &:last-child
      border-top-right-radius: 6px;
      border-bottom-right-radius: 2px;
      margin-right: 0

    & > svg:not(:last-child)
      margin-right: 5px
