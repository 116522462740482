.pricing-tool

  &__disclaimer,
  &__filter,
  &__results
    padding: 16px

  &__disclaimer
    display: flex

    span
      width: calc(100% - 16px)

  &__discount
    min-height: 40px
    display: grid
    grid-template-columns: repeat(12, 1fr)
    column-gap: 8px
    margin-bottom: 8px

    & > div
      grid-column-start: 10
      grid-column-end: 12

      .input__input-helper
        height: auto
        padding-left: 0
        min-height: 0

    .form-button-wrapper
      grid-column-start: 12
      grid-column-end: 13
      width: 100%
      white-space: nowrap

    .form-submit-mobile-btn
      grid-column: span 1
      display: none
      padding: 12px

      img
        height: 24px

  &__tables_deprecated
    display: flex
    flex-direction: row
    justify-content: space-between

    .table
      th
        white-space: normal
        max-width: 100px


@media all and (max-width: 1024px)
  .pricing-tool
    &__results,
    &__tables
      flex-direction: column

    &__device-table
      width: 100%
      padding-bottom: 16px

    &__calendar-table
      width: 100%
      margin-left: 0
