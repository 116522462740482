.notification
  margin-bottom: 8px
  padding: 16px
  border-radius: var(--border-radius)
  min-height: 90px

  &__close-button
    height: 16px
    float: right
    cursor: pointer

  &__title
    margin-bottom: 1rem

  &--error
    background-color: rgba(225,45,75, 0.90)
    color: #fff

  &--success
    background-color: rgba(155,200,25, 0.95)
    color: #fff

  &--warning
    background-color: rgba(225,215,45, 0.90)
    color: #212121

/*============================ Animate notification ============================ */

.notification
  animation: slide-in-top 0.15s cubic-bezier(0.250, 0.460, 0.450, 0.940) .001s both

@-webkit-keyframes slide-in-top
  0%
    transform: translateY(-1000px)
    opacity: 0

  100%
    transform: translateY(0)
    opacity: 1

@keyframes slide-in-top
  0%
    transform: translateY(-1000px)
    opacity: 0

  100%
    transform: translateY(0)
    opacity: 1